import React, { useState, FormEvent, useContext, useEffect } from "react";
import LoginForm from "./LoginForm";
import { UserApi } from "../../services/UserApi";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import LoginTranslations from "../../resources/user/Login";
import { CompanyApi } from "../../services/CompaniesApi";
import { ICompany } from "../../domain/company/ICompany";
import { Card, makeStyles, Typography } from "@material-ui/core";

export interface ILoginState {
    email: string;
    password: string;
    domain: number;
    emailError: string;
    passwordError: string;
    domainError: string;
}

const useStyles = makeStyles({
    root: {
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
      marginBottom: "12px",
      textAlign: "center"
    },
  });


const initialState = {
    email: "",
    password: "",
    domain: 0,
    emailError: "",
    passwordError: "",
    domainError: ""
}

const Login = () => {
    const history = useHistory();
    const appContext = useContext(AppContext);
    const classes = useStyles();
    const [companies, setCompanies] = useState([] as ICompany[]);
    const [state, setState] = useState(initialState as ILoginState);
    const [loading, setLoading] = useState(true as boolean);

    const resource = LoginTranslations;
    const culture = appContext.selectedCulture!.code;

    useEffect(() => {
        const callApi = async () => {
            const data = await CompanyApi.getAll(appContext.selectedCulture!);
            setCompanies(data);
        };
        callApi()
        .then(() => setLoading(false));

    }, [companies.length, appContext.selectedCulture])

    const handleChange = (target:
        EventTarget & HTMLInputElement |
        EventTarget & HTMLSelectElement |
        EventTarget & HTMLTextAreaElement
    ) => {
        if (target.type === "email") {
            setState({ ...state, [target.name]: target.value });
        }

        if (target.type === "password") {
            setState({ ...state, [target.name]: target.value });
        }

        if (target.type === "select-one" && target instanceof HTMLSelectElement) {
            setState({ ...state, [target.name]: target.value });
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValid = validate();
        if (isValid) {
            let response = await UserApi.login({ email: state.email, password: state.password, companyId: state.domain });
            if (response != null) {
                if (response.token != null) {
                    appContext.setAll(response.token, state.email, response.companyId, response.roleName, response.firstName, response.lastName);
                    setState(initialState);
                    history.push("/");
                }
            } else {
                setState({ ...state, emailError: resource[culture].wrongLogin });
            }
        }
    }

    const validate = () => {
        let domainError = "";

        if (state.domain === 0) {
            if (appContext.selectedCulture?.code === "en-GB") {
                domainError = "Select a company!";
            }
            if (appContext.selectedCulture?.code === "et-EE") {
                domainError = "Vali ettevõte!";
            }
        }

        if (domainError) {
            setState({ ...state, domainError });
            return false;
        }

        setState({ ...state, domainError });
        return true;
    };


    if (appContext.jwt != null) {
        return (
            <div className="container-fluid">
            <Card className={classes.root}>
                <Typography className={classes.title} variant="h4" component="h4">
                    You are already logged in!
                </Typography>
            </Card>
            </div>
        )
    } else {
        return (
            <div className={loading ? "container-fluid hidden" : "container-fluid div-animation"}>
                <LoginForm data={state} handleChange={handleChange} companies={companies} handleSubmit={handleSubmit} />
            </div>
        );
    }
}


export default Login;
