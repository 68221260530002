import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import CleaningRow from "./CleaningRow";
import { ICleaningDisplay } from '../../domain/cleaning/ICleaningDisplay';
import UnitDetailsTranslations from "../../resources/unit/UnitDetails";
import CleaningDialog from "./CleaningDialog";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

interface IProps {
    cleanings: ICleaningDisplay[]
}

const DisplayCleanings = (props: IProps) => {
    const appContext = useContext(AppContext);
    const resource = UnitDetailsTranslations;
    const culture = appContext.selectedCulture!.code;
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(null as ICleaningDisplay | null);
    
    const handleClose = () => {
        setOpen(false);
        setModalData(null);
      };

    const handleOpen = (i: number) => {
        var d = props.cleanings[i];
        setModalData(d);
        setOpen((open) => !open);
    }


    if(props.cleanings.length !== 0){
        return (
        <>
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                        <TableCell align="right">{resource[culture].date}</TableCell>
                        <TableCell>{resource[culture].cleantype}</TableCell>
                        <TableCell>{resource[culture].name}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                    {props.cleanings.map((cleaning, index) =>
                    <TableRow className="log-row" key={index} onClick={() => handleOpen(index)}>
                        <CleaningRow cleaning={cleaning} cleaningTypeId={cleaning.cleaningTypeId} />
                    </TableRow>
                    )}
            </TableBody>
            </Table>
            <CleaningDialog handleClose={handleClose} open={open} data={modalData}/>
        </TableContainer>
        </>
        )
    } else {
        return (
            <>
            <hr />
                <h2>{resource[culture].nocleanings}!</h2>    
            </>
        )
    }
}

export default DisplayCleanings;
