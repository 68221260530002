export interface ILayoutResourceStrings {
    email: string,
    company: string,
    profile: string,
    firstname: string,
    lastname: string,
    home: string,
    role: string
}
const engb = "en-GB";
const etee = "et-EE";

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}


export const ProfileTranslations: ILayoutResources = {
    [engb]: {
        firstname: "Firstname",
        lastname: "Lastname",
        email: "Email address",
        company: "Ettevõte",
        profile: "Profile",
        home: "Home",
        role: "User role"
    },
    [etee]: {
        email: "Email",
        company: "Ettevõte",
        profile: "Profiil",
        firstname: "Eesnimi",
        lastname: "Perekonnanimi",
        home: "Esilehele",
        role: "Kasutajagrupp"
    },
}

export default ProfileTranslations;