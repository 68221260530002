export interface ILayoutResourceStrings {
    title: string,
    product: string,
    back: string,
    add: string,
    selectproduct: string
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const AddGoodsFormTranslations: ILayoutResources = {
    [engb]: {
        title: "Add product to unit",
        product: "Product in unit",
        back: "Back",
        add: "Add",
        selectproduct: "Select Product"
    },
    [etee]: {
        title: "Lisa ladustatav kaup",
        product: "Ladustatav kaup",
        back: "Tagasi",
        add: "Lisa",
        selectproduct: "Vali Kaup"

    },
}

export default AddGoodsFormTranslations;