import React, { FormEvent, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import BackButton from "../shared/BackButton";
import { IMaintenanceLogRow } from "../../domain/maintenancelogrow/IMaintenanceLogRow";
import { IMaintenanceLogEntryRowFormState } from "./MaintenanceLogEntryAdd";
import MaintenanceLogEntryFormTranslations from "../../resources/maintenancelog/LogEntryAddForm";
import { Button, Card, makeStyles, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface IProps {
    rowNames: IMaintenanceLogRow[];
    data: IMaintenanceLogEntryRowFormState[];
    hoursWorked: null | Number,
    showHoursAlert: boolean,
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    handleText: (target: EventTarget & HTMLInputElement, index: number) => void;
    handleHours: (target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)) => void;
    handleBool: (target: EventTarget & HTMLInputElement, index: number) => void;
    addBtnDisabled: boolean
}

const useStyles = makeStyles({
    root: {
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
      marginBottom: "12px",
      textAlign: "center"

    },
    btn: {
        width: "100%",
        marginBottom: "0.5rem"
    },
  });


const MaintenanceLogEntryAddForm = (props: IProps) => {
    const appContext = useContext(AppContext);
    const resource = MaintenanceLogEntryFormTranslations;
    const culture = appContext.selectedCulture!.code;
    const classes = useStyles();

    return (
        <>
        {props.rowNames && props.rowNames.length > 0 ?
           <>
           <div className="row">
            <div className="col-sm-10 col-xs-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
            <Card className={classes.root}>
                <Typography className={classes.title} variant="h4" component="h4">
                    {resource[culture].addTitle}
                </Typography>
                <hr/>
                
                    
            
                <form className="form-signin" onSubmit={props.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="comment"><b>{resource[culture].name}</b></label>
                            <p>{appContext.firstName + " " + appContext.lastName}</p>
                        </div>
                        <div className="form-group">
                            <TextField value={props.hoursWorked} onChange={(e) => props.handleHours(e.target)} id="hoursWorked" label={resource[culture].hoursWorked} type="hoursWorked" />
                        </div>
                        {props.data.map((row, index) => (
                            <div className="form-group" key={index}>
                                <label className="form-check-label" htmlFor={"checkbox " + index}><b>{props.rowNames[index].description}</b></label>
                                <br />
                                <input style={{ paddingTop: "25px", paddingBottom: "6px", width: "1.55rem", height: "1.55rem" }} checked={props.data[index]!.done} name={"checkbox " + index} type="checkbox" onChange={(e) => props.handleBool(e.target, index)} id={"checkbox " + index} />
                                <br />
                                <div style={{ fontSize: 12, color: "red" }}>{props.data[index].doneError}</div>
                                <a data-toggle="collapse" className="btn btn-primary btn-sm" aria-expanded="false" href={"#commentCollapse" + index}  aria-controls={"commentCollapse" + index}>
                                {resource[culture].comment}
                                </a>
                                <div style={{ paddingTop: "10px"}} className="collapse" id={"commentCollapse" + index}>
                                    <input value={props.data[index]!.comment} className="form-control" name="comment" type="text" onChange={(e) => props.handleText(e.target, index)} id="comment" placeholder="..." />
                                    <div style={{ fontSize: 12, color: "red" }}>{props.data[index].commentError}</div>
                                </div>
                            </div>
                        ))}
                        {props.showHoursAlert ? <Alert severity="error">{resource[culture].hoursWorkedAlert}</Alert> : <></>}
                        <br/>

                        <Button disabled={props.addBtnDisabled} variant="outlined" color="primary" className={classes.btn} type="submit">{resource[culture].addbtn}</Button>
                        <BackButton classDescription="btn btn-lg btn-primary btn-block" btnString={resource[culture].back}/>
                        <br/>
                    </form>
            </Card>
            </div>
            </div>
            </>
            : <></>
        }
        </>
    )
}

export default MaintenanceLogEntryAddForm;