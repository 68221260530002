export interface ILayoutResourceStrings {
    name: string,
    title: string,
    comment: string,
    back: string,
    logrow: string,
    nologs: string,
    delete: string,
    addentry: string,
    date: string,
    close: string,
    hours: string

}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const MaintenanceLogDetailsTranslations: ILayoutResources = {
    [engb]: {
        name: "Name",
        title: "Details for log",
        nologs: "No entries to display!",
        comment: "Comment",
        back: "Back",
        logrow: "Log row",
        delete: "Delete log",
        addentry: "Add a new entry",
        date: "Date",
        close: "Close",
        hours: "Number of hours"
    },
    [etee]: {
        name: "Nimi",
        title: "Halduslogi üksikasjad",
        nologs: "Sissekanded puuduvad!",
        comment: "Kommentaar",
        back: "Tagasi",
        logrow: "Logi rida",
        delete: "Kustuta logi",
        addentry: "Tee uus sissekanne",
        date: "Kuupäev",
        close: "Sulge",
        hours: "Tundide arv"
    },
}

export default MaintenanceLogDetailsTranslations;