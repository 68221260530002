import { Card, Divider, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import HomeTranslations from "../../resources/shared/Home";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: "1rem",
        padding: "1rem"
    },
    title: {
        marginBottom: "12px"
    },
    pos: {
        marginTop: "20px",
        width: "100%",
        height: "4rem",
        backgroundColor: '#ffffff',
        borderColor: '#007bff',
        color: "#007bff",

        '&:hover': {
            backgroundColor: "#e1e8f0",
            borderColor: '#003e81',
            color: "#003e81", 
        }
    },
    p: {
        marginTop: 20
    }
  });

const About = () => {
    const classes = useStyles();
    const resource = HomeTranslations;
    const appContext = useContext(AppContext);
    const culture = appContext.selectedCulture!.code;
    

    return <div className="container-fluid">
        <Card className={classes.root}>
          <Typography variant="h4" component="h4" className={classes.title}>
            About
          </Typography>
          <Divider/>
          <Typography component="p" className={classes.p}>
            {resource[culture].aboutText}
          </Typography>
          <Typography component="p" className={classes.p}>
            <b>{resource[culture].bugText}</b>
            <br/>
            {resource[culture].emailText} <a href="mailto:siim.melles@gmail.com">siim.melles@gmail.com</a>
          </Typography>
        </Card>
    </div>
}




export default About;
