import { ICulture } from '../domain/ICulture';
import { IProduct } from '../domain/product/IProduct';
import Axios from 'axios';

export abstract class ProductApi {

    private static axios = Axios.create(
        {
            baseURL: process.env.REACT_APP_URLPATH + "Product",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    static async getAll(companyId: number, jwt: string, culture: ICulture): Promise<IProduct[]> {
        const url = "";
        try {
            const response = await this.axios.get<IProduct[]>(url, { 
                params: { companyId: companyId, culture: culture.code }, 
                headers: { Authorization: "Bearer " + jwt } });
            //console.log("getAll Response", response);
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.log("error: ", (error as Error).message);
            return [];
        }
    }
}