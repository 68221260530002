import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Button, Collapse, Divider, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { ILayoutResources } from "../../resources/shared/Home";
import LatestCleanings from '../cleaning/LatestCleanings';
import { ILatestCleaningDisplay } from '../../domain/cleaning/ILatestCleaningDisplay';

interface IProps {
  resource: ILayoutResources
  culture: string,
  cleanings: ILatestCleaningDisplay[]
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: "1rem",
    padding: "1rem"
  },
  title: {
    marginBottom: "12px"
  },
  pos: {
    marginTop: "20px",
    width: "100%",
    height: "4rem",
    backgroundColor: '#ffffff',
    borderColor: '#007bff',
    color: "#007bff",
  '&:hover': {
    backgroundColor: "#e1e8f0",
    borderColor: '#003e81',
    color: "#003e81",
    
  },
},
});

const LoggedInHome = (props: IProps) => {
  const appContext = useContext(AppContext);
  const classes = useStyles();
  const [showCleanings, setShowCleanings] = useState(false as boolean);

  return (
      <>
        <div className="container div-animation">
        <Card className={classes.root}>
          <Typography variant="h4" component="h4" className={classes.title}>
            {props.resource[props.culture].greeting}, {appContext.firstName}
          </Typography>
          <Divider />
          <div className="row">
            <div className="col-md-6">
              <Button variant="outlined" color="primary" className={classes.pos} component={Link} to="/Warehouses">{props.resource[props.culture].viewWarehouses} &raquo;</Button>
            </div>
            {
            appContext.roleName === "guest" ? <></> : 
              <>
              <div className="col-md-6">
                <Button variant="outlined" color="primary" className={classes.pos} component={Link} to="/MaintenanceLogs">{props.resource[props.culture].maintenanceLog} &raquo;</Button>
              </div>
              <div className="col-md-6">
                <Button variant="outlined" color="primary" className={classes.pos} component={Link} to="/Cleaning">{props.resource[props.culture].addCleaning} &raquo;</Button>
              </div>
              <div className="col-md-6">
                <Button variant="outlined" color="primary" className={classes.pos} component={Link} to="/CleaningSearch">{props.resource[props.culture].search} &raquo;</Button>
              </div>
              <div className="col-xl-12">
                <Button variant="outlined" color="primary" className={classes.pos} onClick={() => setShowCleanings(prevShow => !prevShow)}>{props.resource[props.culture].viewLatest} &raquo;</Button>
              </div>
              </>
            }      
          </div>
          </Card>
          <Collapse in={showCleanings} className="col align-self-center" style={{padding: "0px"}}>
            <LatestCleanings cleanings={props.cleanings} />
          </Collapse>
          </div>
      </>
  )
}

export default LoggedInHome;
