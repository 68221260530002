import Axios from "axios";
import { ICleaningGoal } from "../domain/cleaninggoal/ICleaningGoal";
import { ICulture } from "../domain/ICulture";


export abstract class CleaningGoalApi {

    private static axios = Axios.create(
        {
            baseURL: process.env.REACT_APP_URLPATH + "CleaningGoals",
            headers: {
                'Content-Type': 'application/json'  
            }
        }
    );

    static async getAll(unitId: number, jwt: string, culture: ICulture): Promise<ICleaningGoal[]> {
        const url = "";
        try {
            const response = await this.axios.get<ICleaningGoal[]>(
                url,
                {
                    params: {
                        unitId: unitId,
                        culture: culture.code
                    },
                    headers: {
                        Authorization: "Bearer " + jwt
                    }
                });
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.log("error: ", (error as Error).message);
            return [];
        }
    }
}