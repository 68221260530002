import { useHistory } from "react-router-dom";
import React from "react";

interface IProps {
  classDescription: string,
  btnString: string
}

const BackButton = (props: IProps) => {
  let history = useHistory();

  const buttonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    history.goBack()
  }

  return (
    <>
      <button onClick={buttonClicked} className={props.classDescription} style={{ marginBottom: "1.5rem" }}>{props.btnString}</button>
    </>
  );
};

export default BackButton;