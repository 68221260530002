export interface ILayoutResourceStrings {
    language: string,
    maintenanceLog: string,
    warehouses: string,
    addCleaning: string,
    search: string,
    logIn: string,
    logOut: string,
    home: string,
    back: string

}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const Layout: ILayoutResources = {
    [engb]: {
        language: "Language",
        maintenanceLog: "Maintenance Log",
        warehouses: "Warehouses",
        addCleaning: "Add cleaning",
        search: "Search",
        logIn: "Log in",
        logOut: "Log out",
        home: "Home",
        back: "Back"
    },
    [etee]: {
        language: "Keel",
        maintenanceLog: "Logiraamatud",
        warehouses: "Laod",
        addCleaning: "Lisa koristus",
        search: "Otsing",
        logIn: "Logi sisse",
        logOut: "Logi välja",
        home: "Esileht",
        back: "Tagasi"
    }
}

export default Layout;