import React, { useContext, useEffect, useState, FormEvent } from "react";
import { MaintenanceLogRowApi } from "../../services/MaintenanceLogRowApi";
import { AppContext } from "../../context/AppContext";
import { IMaintenanceLogRow } from "../../domain/maintenancelogrow/IMaintenanceLogRow";
import MaintenanceLogEntryAddForm from "./MaintenanceLogEntryAddForm";
import { MaintenanceLogEntryApi } from "../../services/MaintenanceLogEntryApi";
import { MaintenanceLogRowEntryApi } from "../../services/MaintenanceLogRowEntryApi";
import { useHistory, useParams } from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

export interface IMaintenanceLogEntryRowFormState {
    done: boolean;
    comment: string;
    doneError: string;
    commentError: string;
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

//TODO: Changing language in this view crashes page

const MaintenanceLogEntryAdd = () => {
    const history = useHistory();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const [logRows, setLogRows] = useState([] as IMaintenanceLogRow[]);
    const [state, setState] = useState([] as IMaintenanceLogEntryRowFormState[])
    const [isLoading, setIsLoading] = useState(true as boolean);
    const [addBtnDisabled, setAddBtnDisabled] = useState(false);
    const [showHoursAlert, setShowHoursAlert] = useState(false);
    const [hoursWorked, setHoursWorked] = useState(null as Number | null);

    let { id } : any = useParams();

    useEffect(() => {
        const getMaintenanceLogEntryRows = async () => {
            let rows = await MaintenanceLogRowApi.getAll(appContext.jwt!, id, appContext.selectedCulture!);
            if (rows.length !== 0) {
                rows.sort((a, b) => a.sortingNumber > b.sortingNumber ? 1 : -1);
                setLogRows(rows);

                //TODO: change this
                let formRows = [...state] as IMaintenanceLogEntryRowFormState[];
                for (let i = 0; i < rows.length; i++) {
                    formRows.push({ done: false, comment: "", doneError: "", commentError: "" })
                }
                setState(formRows)
            }
        }
        getMaintenanceLogEntryRows().then(() => setIsLoading(false));
    // eslint-disable-next-line
    }, [appContext.jwt, id])

    const handleBool = (target: EventTarget & HTMLInputElement, index: number) => {
        let formRows = [...state];
        formRows[index].done = target.checked;
        setState(formRows);
    }

    const handleHours = (target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)) => {
        const re = /^[0-9\b]+$/;
        if (target.value === '' || re.test(target.value)) {
            setShowHoursAlert(false);
            setHoursWorked(+target.value);
         }
    }

    const handleText = (target: EventTarget & HTMLInputElement, index: number) => {
        let formRows = [...state];
        formRows[index].comment = target.value
        if (target.value.length > 128 || (target.value.length < 3 && target.value.length !== 0)) {
            formRows[index].commentError = "Comment length has to be between 1 and 128";
        } else {
            formRows[index].commentError = "";
        }
        setState(formRows)
    }
    const createEntry = async () => {
        return await MaintenanceLogEntryApi.createNew(appContext.jwt!, { maintenanceLogId: id, hoursWorked: hoursWorked as number }, appContext.selectedCulture!);
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValid = validate() && hoursWorked !== null;
        if (hoursWorked === null) setShowHoursAlert(true);
        if (isValid) { 
            handleToggle();
            setIsLoading(isLoading => !isLoading);
            createEntry().then(async entry => {
                if (entry) {
                    for (let i = 0; i < state.length; i++) {
                        let row = state[i];
                        console.log(hoursWorked);
                        await MaintenanceLogRowEntryApi.createNew(
                            appContext.jwt!,
                            {
                                done: row.done,
                                comment: row.comment,
                                maintenanceLogEntryId: entry.id,
                                logRowId: logRows[i].id,
                            }
                        );
                    }
                    history.goBack();
                }
            });
        }
    }

    const validate = () => {
        let valid = true;
        let rows = [...state];
        for (let i = 0; i < state.length; i++) {
            const row = rows[i];
            if (row.comment) {
                if (row.comment.length > 128) {
                    valid = false;
                    row.commentError = "Comment is too long!";
                } else {
                    row.commentError = "";
                }
            }            
        }

        setState(rows);
        return valid;
    }

      const handleToggle = () => {
        setAddBtnDisabled(!addBtnDisabled);
      };

    return (
        <div>
        <Backdrop className={classes.backdrop} open={addBtnDisabled}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation"}>
                <MaintenanceLogEntryAddForm
                addBtnDisabled={addBtnDisabled}
                data={state}
                hoursWorked={hoursWorked}
                rowNames={logRows}
                handleText={handleText}
                handleBool={handleBool}
                handleHours={handleHours}
                handleSubmit={handleSubmit} 
                showHoursAlert={showHoursAlert}/>
        </div>
        </div>
    )
}

export default MaintenanceLogEntryAdd;