import React, { useEffect, useContext, useState } from "react";
import BackButton from "../shared/BackButton";
import { AppContext } from "../../context/AppContext";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { IMaintenanceLog } from "../../domain/maintenancelog/IMaintenanceLog";
import { IMaintenanceLogRow } from "../../domain/maintenancelogrow/IMaintenanceLogRow";
import { IMaintenanceLogRowEntry } from "../../domain/maintenancelogrowentry/IMaintenanceLogRowEntry";

import MaintenanceLogEntryRows from "./MaintenanceLogEntryRows";
import MaintenanceLogDetailsTranslations from "../../resources/maintenancelog/LogDetails";

import { MaintenanceLogApi } from "../../services/MaintenanceLogApi";
import { MaintenanceLogEntryApi } from "../../services/MaintenanceLogEntryApi";
import { MaintenanceLogRowApi } from "../../services/MaintenanceLogRowApi";
import { MaintenanceLogRowEntryApi } from "../../services/MaintenanceLogRowEntryApi";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MaintenanceLogDetailsDialog from "./MaintenanceLogDetailsDialog";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
        marginBottom: "12px"
    },
    pos: {
      marginBottom: 12,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1.5,
        color: '#ffffff',
      },
  }));

interface IMaintenanceLogEntryDisplay {
    id: number;
    recordedAt: Date;
    hoursWorked: number;
    maintenanceLogId: number;
    appUserId: number;
    appUserFullName: string;
    entryRows: IMaintenanceLogRowEntry[];
}

const MaintenanceLogDetails = () => {
    const classes = useStyles();

    let { id } : any = useParams();
    const [maintenanceLog, setMaintenanceLog] = useState({} as IMaintenanceLog);
    const [maintenanceLogRows, setMaintenanceLogRows] = useState([] as IMaintenanceLogRow[]);
    const [logEntries, setLogEntries] = useState([] as IMaintenanceLogEntryDisplay[]);
    const [isLoading, setIsLoading] = useState(true as boolean);
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(null as IMaintenanceLogEntryDisplay | null);
    const [addBtnDisabled, setAddBtnDisabled] = useState(true);

    const history = useHistory();
    const appContext = useContext(AppContext);

    const resource = MaintenanceLogDetailsTranslations;
    const culture = appContext.selectedCulture!.code;

    const handleClose = () => {
        setOpen(false);
        setModalData(null);
      };

    const handleOpen = (i: number) => {
        var d = logEntries[i];
        setModalData(d);
        setOpen(true);
    }

    useEffect(() => {
        const getMaintenanceLog = async () => {
            const maintenanceLog = await MaintenanceLogApi.getDetails(appContext.jwt!, id, appContext.selectedCulture!);
            if (maintenanceLog) {
                setMaintenanceLog(maintenanceLog);
            }
            
            const maintenanceLogRows = await MaintenanceLogRowApi.getAll(appContext.jwt!, id, appContext.selectedCulture!);
            
            if (maintenanceLogRows) {
                for (var maintenanceLogRow of maintenanceLogRows) {
                    const logRowEntries = await MaintenanceLogRowEntryApi.getAll(appContext.jwt!, maintenanceLogRow.id);
                    maintenanceLogRow.entries = logRowEntries;
                }
                maintenanceLogRows.sort((a, b) => a.sortingNumber > b.sortingNumber ? 1 : -1);
                setMaintenanceLogRows(maintenanceLogRows);
            }
            return maintenanceLogRows;
        };

        const getMaintenanceLogEntries = async (logRows: IMaintenanceLogRow[]) => {
            const logEntries = await MaintenanceLogEntryApi.getAll(appContext.jwt!, id, appContext.selectedCulture!);
            if (logEntries) {
                let mappedLogEntries = [] as IMaintenanceLogEntryDisplay[];
                for (var entry of logEntries) {
                    var rowEntries = [] as IMaintenanceLogRowEntry[];
                    for (var logRow of logRows) {
                        // eslint-disable-next-line
                        var rowEntry = logRow.entries.find(x => x.maintenanceLogEntryId === entry.id);
                        if (rowEntry) rowEntries.push(rowEntry);
                    }
                    mappedLogEntries.push(
                        {
                            id: entry.id,
                            appUserId: entry.appUserId,
                            appUserFullName: entry.appUserFullName,
                            recordedAt: new Date(entry.recordedAt),
                            hoursWorked: entry.hoursWorked,
                            maintenanceLogId: entry.maintenanceLogId,
                            entryRows: rowEntries
                        });
                    }
                    setLogEntries(mappedLogEntries.sort((a, b) => b.recordedAt.getTime() - a.recordedAt.getTime()));
                }
        }

        getMaintenanceLog()
            .then((rows) => getMaintenanceLogEntries(rows))
            .then(()=> handleToggle());
    // eslint-disable-next-line
    }, [appContext.jwt, id, appContext.selectedCulture])

    const deleteLog = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        await MaintenanceLogApi.delete(id, appContext.jwt!);
        history.push("/MaintenanceLogs");
    }
    const handleToggle = () => {
        setAddBtnDisabled(!addBtnDisabled);
        setIsLoading(!isLoading);
      };

    return (
        <div>
            <Backdrop className={classes.backdrop} open={addBtnDisabled}>
            <CircularProgress color="inherit" />
        </Backdrop>
        
        <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation"}>

        <Card className={classes.root}>
            <Typography className={classes.title} variant="h4" component="h4">
                {maintenanceLog.name}
            </Typography>
            <p>{maintenanceLog.comment}</p>
            <Link to={"/MaintenanceLogs/"+ id + "/AddLogEntry"} className="btn btn-success btn-margin">{resource[culture].addentry}</Link>
            {
                appContext.roleName === "admin" ? <button className="btn btn-danger btn-margin" onClick={(e) => { deleteLog(e) }}>{resource[culture].delete}</button> : <></>
            }
        
            <div>
                <BackButton classDescription="btn btn-primary btn-margin" btnString={resource[culture].back}/>
            </div>
        </Card>
        <div>
            {logEntries.length === 0 ? <h3>{resource[culture].nologs}</h3> : 
                <>
                    <TableContainer component={Paper} className={""}>
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">{resource[culture].name}</TableCell>
                            <TableCell align="right">{resource[culture].date}</TableCell>
                            {maintenanceLogRows.map((row, index) => 
                                <TableCell key={row.sortingNumber}>{row.description}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {logEntries.map((entry, index) =>
                        <TableRow className="log-row" key={index} onClick={() => handleOpen(index)}>
                            <MaintenanceLogEntryRows userFullName={entry.appUserFullName} date={entry.recordedAt} key={index} maintenanceLogRows={maintenanceLogRows} logEntryRows={entry.entryRows} />
                        </TableRow>
                    )}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    <MaintenanceLogDetailsDialog handleClose={handleClose} open={open} data={modalData} logRows={maintenanceLogRows}/>
                </>
            }
        </div>
    </div>
    </div>
    )
}

export default MaintenanceLogDetails;