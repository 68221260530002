import React, { useContext } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IMaintenanceLogRowEntry } from '../../domain/maintenancelogrowentry/IMaintenanceLogRowEntry';
import { IMaintenanceLogRow } from '../../domain/maintenancelogrow/IMaintenanceLogRow';
import MaintenanceLogDetailsTranslations from '../../resources/maintenancelog/LogDetails';
import { AppContext } from '../../context/AppContext';
import { DateFormatter } from '../../utils/Dateutils';

interface IMaintenanceLogEntryDisplay {
    id: number;
    recordedAt: Date;
    hoursWorked: number;
    maintenanceLogId: number;
    appUserId: number;
    appUserFullName: string;
    entryRows: IMaintenanceLogRowEntry[];
}

interface IProps {
    handleClose: () => void,
    open: boolean,
    data: IMaintenanceLogEntryDisplay | null,
    logRows: IMaintenanceLogRow[];
}

export default function MaintenanceLogDetailsDialog(props: IProps) {
  const appContext = useContext(AppContext);
  const resource = MaintenanceLogDetailsTranslations;
  const culture = appContext.selectedCulture!.code;

  const body = (
    <div>
    {props.data === null ?
        <></>
        : 
        <>
        <DialogTitle id="alert-dialog-title">{DateFormatter(props.data!.recordedAt)}</DialogTitle>
        <DialogContent>
        <h6>{props.data.appUserFullName}</h6>
        <h6>{resource[culture].hours}: {props.data.hoursWorked}</h6>
        {props.logRows.map((row, index) => 
            <div key={index}>
                <dt>{row.description}:
                    {props.data!.entryRows.find(x => x.logRowId === row.id)!.done ? 
                        <Checkbox disabled checked inputProps={{ 'aria-label': 'disabled checked checkbox' }}/> :
                        <Checkbox disabled inputProps={{ 'aria-label': 'disabled checkbox' }}/>
                    }
                </dt>
                {props.data!.entryRows.find(x => x.logRowId === row.id)!.comment ? 
                <>
                    <div className="p-box">{props.data!.entryRows.find(x => x.logRowId === row.id)!.comment}</div>   
                </>
                :
                <></>
                }
            </div>
        )}  
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} variant="outlined" color="secondary">{resource[culture].close}</Button>
        </DialogActions>
        </>
        }
    </div>
  );

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {body}
      </Dialog>
    </div>
  );
}
