import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IProductInUnit } from '../../domain/productinunit/IProductInUnit';
import { DateFormatter } from '../../utils/Dateutils';

interface IProps {
    handleClose: () => void,
    open: boolean,
    data: IProductInUnit | null,
    removeProduct: (event: React.MouseEvent<HTMLButtonElement>, productInUnitId: number) => void
}

const useStyles = makeStyles({
  root: {
    width:"100%",
    marginBottom: "1rem",
    padding: "1rem"
  },
  title: {
    margin: "0px 60px 0px 30px",

  },
  pos: {
    width: "100%"
  },
});

export default function GoodsEditDialog(props: IProps) {
    const classes = useStyles();
  
    const body = (
      <div>
          <DialogTitle id="alert-dialog-title" >Goods</DialogTitle>
          {props.data === null ?
              <></>
              : 
              <>
                <DialogContent className={classes.title}>
                  <dt>From</dt> 
                  <dd>{DateFormatter(props.data.from)}</dd>
                  <dt>Product name</dt> 
                  <dd>{props.data.productName}</dd>
                  
                  <Button onClick={(e) => { props.removeProduct(e, props.data!.id) }} className={classes.pos} variant="outlined" color="secondary">Remove</Button>
                  <DialogActions>
                    <Button onClick={props.handleClose} className={classes.pos} variant="outlined">Close</Button>
                  </DialogActions>
                </DialogContent>
            </>
          }
      </div>
    );
  
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.root}
        >
          {body}
        </Dialog>
      </div>
    );
  }
  