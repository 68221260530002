import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {

    let date = new Date();
        return (
        <div className="container-fluid">
            <hr />
        <p className="footer-text">Bellpepper OÜ | {date.getFullYear()} | <Link to="/About" style={{textAlign: "right"}}>About</Link></p>
        </div>
    );
}
export default Footer;