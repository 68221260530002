import React from "react";
import { IFormState } from "./Form";

interface IProps {
    data: IFormState;
    handleChange: (target:
        EventTarget & HTMLInputElement |
        EventTarget & HTMLSelectElement |
        EventTarget & HTMLTextAreaElement
    ) => void
}

const FormView = (props: IProps) => (

    <form>
        <div className="form-group">
            <label htmlFor="exampleInputEmail">Email address</label>
            <input value={props.data.input} name="input" onChange={(e) => props.handleChange(e.target)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
        </div>
        <div className="form-check">
            <input checked={props.data.checkbox} name="checkbox" onChange={(e) => props.handleChange(e.target)} type="checkbox" className="form-check-input" id="exampleCheck1" />
            <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
        </div>
        <div className="form-group">
            <label htmlFor="exampleFormControlSelect1">Example select</label>
            <select value={props.data.select} name="select" onChange={(e) => props.handleChange(e.target)} className="form-control" id="exampleFormControlSelect1">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
        </div>

        {
            props.data.radioGroup1.map((item, index) => (
                <div className="form-check form-check-inline" key={index}>
                    <input checked={props.data.radioGroup1[index]} value={index} name="radioGroup1" onChange={(e) => props.handleChange(e.target)} className="form-check-input" type="radio" id={"radio-" + index} />
                    <label className="form-check-label" htmlFor={"radio-" + index}>{index}</label>
                </div>
            ))
        }

        <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Example textarea</label>
            <textarea value={props.data.textarea} name="textarea " onChange={(e) => props.handleChange(e.target)} className="form-control" id="exampleFormControlTextarea1" rows={5}></textarea>
        </div>

        <button type="submit" className="btn btn-primary">Submit</button>
    </form>

);

export default FormView;