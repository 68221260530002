import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { IProductInUnit } from "../../domain/productinunit/IProductInUnit";
import { Link } from "react-router-dom";
import UnitDetailsTranslations from "../../resources/unit/UnitDetails";
import { Button, createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core";
import { green } from '@material-ui/core/colors';

interface IProps {
    productsInUnit: IProductInUnit[]
    removeProduct: (event: React.MouseEvent<HTMLButtonElement>, productInUnitId: number) => void,
    handleOpen: (index: number) => void,
    warehouseId: number,
    unitId: number

}


const useStyles = makeStyles({
    root: {
      minWidth: 275,
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
        marginBottom: "12px"
    },
    btnMain: {
        width: "100%",
        marginBottom: "10px",
        '&:hover': {
            textDecoration: "none",
            color: "black"
          }
    },
    btn: {
      width: "100%",
      marginBottom: "10px",
      '&:hover': {
        backgroundColor: "#fef5f8",
        color: "#dc004d"
      }
    },
  });

  const theme = createMuiTheme({
    palette: {
      primary: green,
    },
  });


const GoodsEdit = (props: IProps) => {
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const resource = UnitDetailsTranslations;
    const culture = appContext.selectedCulture!.code;
    
    return (
        <>
            {
                props.productsInUnit && props.productsInUnit.length !== 0 ?
                <>
                    <hr />
                    <h4>{resource[culture].goodsstored}:</h4>

                    <div>
                        
                            {props.productsInUnit.map((productInUnit, index) =>
                                <Button variant="outlined" onClick={() => props.handleOpen(index)} key={index} className={classes.btn}>{productInUnit.productName}</Button>
                            )}
                            <ThemeProvider theme={theme}>
                                <Button variant="contained" color="primary" className={classes.btnMain} component={Link} to={"/Warehouses/" + props.warehouseId + "/Units/"+ props.unitId + "/AddGoods"}>
                                  {resource[culture].add}
                                </Button>
                            </ThemeProvider>
                    </div>
                </> :
                <>
                    <hr />
                    <h4>{resource[culture].nogoods}!</h4>
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" color="primary" className={classes.btnMain} component={Link} to={"/Warehouses/" + props.warehouseId + "/Units/"+ props.unitId + "/AddGoods"}>{resource[culture].add}</Button>
                    </ThemeProvider>

                </>
            }

        </>
    );
}

export default GoodsEdit;