import { TableCell } from "@material-ui/core";
import React from "react";
import { ICleaningDisplay } from '../../domain/cleaning/ICleaningDisplay';
import { DateFormatter } from "../../utils/Dateutils";

interface IProps {
    cleaning: ICleaningDisplay,
    cleaningTypeId: number
}

const CleaningRow = (props: IProps) => {
    let date = new Date(props.cleaning.recordedAt);
    
    return (
        <>
            <TableCell align="right">{DateFormatter(date)}</TableCell>
            <TableCell>{props.cleaning.cleaningTypeName}</TableCell>
            <TableCell>{props.cleaning.appUserFullNames}</TableCell>
        </>
    )
}

export default CleaningRow;