export interface ILayoutResourceStrings {
    name: string,
    add: string,
    addbtn: string,
    comment: string,
    back: string,
    addrow: string,
    removerow: string,
    logrow: string,
    nameplaceholder: string,
    commentplaceholder: string,
    rowname: string
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const MaintenanceLogFormTranslations: ILayoutResources = {
    [engb]: {
        name: "Name",
        add: "Create a new maintenance log",
        addbtn: "Add",
        comment: "Comment",
        commentplaceholder: "Add details here ...",
        back: "Back",
        addrow: "Add a new row",
        removerow: "Remove row",
        logrow: "Log row",
        rowname: "Row name ...",
        nameplaceholder: "Maintenance log name"
    },
    [etee]: {
        name: "Nimetus",
        add: "Lisa uus halduslogi",
        addbtn: "Lisa",
        comment: "Kommentaar",
        commentplaceholder: "Lisa üksikasjad ...",
        back: "Tagasi",
        addrow: "Lisa uus rida",
        removerow: "Eemalda rida",
        logrow: "Logi rida",
        rowname: "Rea nimi ...",
        nameplaceholder: "Halduslogi nimetus"
    },
}

export default MaintenanceLogFormTranslations;