import React, { useState, useContext, FormEvent, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import AddGoodsForm from "./AddGoodsForm";
import ProductsInUnitsApi from "../../services/ProductsInUnitsApi";
import { useHistory, useParams } from "react-router-dom";
import { ProductApi } from "../../services/ProductApi";
import { IProduct } from "../../domain/product/IProduct";

interface IProductInUnitAddState {
    unitId: number;
    productId: number;
    productError: string;
}

const initialState = {
    unitId: 0,
    productId: 0,
    productError:""
}

const AddGoods = () => {
    let { id } = useParams();
    const appContext = useContext(AppContext);
    const history = useHistory();
    const [state, setState] = useState(initialState as IProductInUnitAddState);
    const [products, setProducts] = useState([] as IProduct[]);
    const [isLoading, setIsLoading] = useState(true as boolean);

    useEffect(() => {
        const callApi = async () => {
            const dbProducts = await ProductApi.getAll(appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
            if (dbProducts) {
                setProducts(dbProducts);
            }
        };

        callApi().then(() => setIsLoading(false));

    }, [appContext.jwt, appContext.companyId, appContext.selectedCulture]);


    const handleChange = (target: EventTarget & HTMLSelectElement) => {
        if (target.type === "select-one" && target instanceof HTMLSelectElement) {
            setState({ ...state, [target.name]: target.value });
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValid = validate();
        if (isValid) {
            console.log(state);
            ProductsInUnitsApi.createNew(appContext.jwt!, { unitId: id, productId: state.productId })
            history.goBack();
        }
    }

    const validate = () => {
        if (state.productId === 0) {
            setState({...state, productError: "Select a product!"})
            return false;
        }
        state.productError = "";
        return true;
    };

    return (
        <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation"}>
            <AddGoodsForm data={state} handleChange={handleChange} handleSubmit={handleSubmit} products={products} productError={state.productError} />
        </div>
    )
}

export default AddGoods;