import { ICulture } from '../domain/ICulture';
import Axios from 'axios';

export abstract class CultureApi {
    
    private static axios = Axios.create(
        {
            baseURL:  process.env.REACT_APP_URLPATH + "Cultures",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    static async getAll(): Promise<ICulture[]> {
        const url = "";
        try {
            const response = await this.axios.get<ICulture[]>(url);
            //console.log("getAll Response", response);
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.log("error: ", (error as Error).message);
            return [];
        }
    }
}