export interface ILayoutResourceStrings {
    unit: string,
    date: string,
    cleantype: string,
    name: string,
    comment: string,
    nogoods: string,
    goodsstored: string,
    remove: string,
    add: string,
    details: string,
    back: string,
    backtolist: string,
    nocleanings: string,
    cleaningGoals: string,
    noGoals: string,
    close: string,
    entry: string
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const UnitDetailsTranslations: ILayoutResources = {
    [engb]: {
        unit: "Unit",
        date: "Date",
        cleantype: "Cleaning type",
        name: "Name",
        comment: "Comment",
        nogoods: "No goods specified",
        goodsstored: "Goods stored in unit",
        remove: "Remove",
        add: "Add",
        details: "Details",
        back: "Back",
        backtolist: "Back to list",
        nocleanings: "No cleanings to display",
        cleaningGoals: "Monthly cleaning goals",
        noGoals: "No cleaning goals set for this unit!",
        close: "Close",
        entry: "Entry"
    },
    [etee]: {
        unit: "Ladu",
        date: "Kuupäev",
        cleantype: "Koristusliik",
        name: "Nimi",
        comment: "Kommentaar",
        nogoods: "Kaup pole lisatud",
        goodsstored: "Ladustatav kaup",
        remove: "Eemalda",
        add: "Lisa",
        details: "Info",
        back: "Tagasi",
        backtolist: "Tagasi nimekirja",
        nocleanings: "Sissekanded puuduvad",
        cleaningGoals: "Jooksva kuu koristuseesmärgid",
        noGoals: "Laoüksusele pole eesmärke määratud!",
        close: "Sulge",
        entry: "Sissekanne"

        },
}

export default UnitDetailsTranslations;