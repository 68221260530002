import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { HomeTranslations } from "../../resources/shared/Home"
import LoggedInHome from "./LoggedInHome";
import DefaultHome from "./DefaultHome";
import { CleaningApi } from "../../services/CleaningApi";
import { CleaningTypeApi } from "../../services/CleaningTypeApi";
import { ILatestCleaningDisplay } from "../../domain/cleaning/ILatestCleaningDisplay";

const Home = () => {
  const resource = HomeTranslations;
  const appContext = useContext(AppContext);
  const culture = appContext.selectedCulture!.code;
  const [isLoading, setIsLoading] = useState(true as boolean);
  const [cleanings, setCleanings] = useState([] as ILatestCleaningDisplay[]);

  useEffect(() => {
    setIsLoading(true);
    const callApis = async () => {
      if (appContext.jwt !== null) {
        const dbLatestCleanings = await CleaningApi.getLatest(appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
        const dbCleaningTypes = await CleaningTypeApi.getAll(appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
        if (dbLatestCleanings !== null) {
          let latestCleanings = [] as ILatestCleaningDisplay[];
          dbLatestCleanings.forEach(element => {
            latestCleanings.push(
                {
                    id: element.id,
                    appUsersFullNames: element.appUsersFullNames,
                    comment: element.comment,
                    recordedAt: new Date(element.recordedAt),
                    unitId: element.unitId,
                    unitName: element.unitName,
                    cleaningTypeId: element.cleaningTypeId,
                    cleaningTypeName: dbCleaningTypes.find(type => type.id === element.cleaningTypeId)?.name!
                } as ILatestCleaningDisplay
            )
        });
        setCleanings(latestCleanings.sort((a, b) => b.recordedAt.getTime() - a.recordedAt.getTime()));
        }
      }
    }
    callApis().then(() => setIsLoading(false));
    
  }, [appContext.jwt, appContext.selectedCulture, appContext.companyId]);

  return (
    <div className={isLoading ? "hidden" : "div-animation"}>
      <div className="jumbotron">
        <div className="container div-onload-animation">
          <h1 className="display-6 font-weight-normal text-white">{resource[culture].headerTitle}</h1>
        </div>
        
      </div>
      {
        !appContext.jwt ?
          <DefaultHome resource={resource} culture={culture}/>
          :
          <LoggedInHome resource={resource} culture={culture} cleanings={cleanings}/>
      }
    </div>
  );
}

export default Home;