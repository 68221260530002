import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { ICleaningGoal } from "../../domain/cleaninggoal/ICleaningGoal";
import UnitDetailsTranslations from "../../resources/unit/UnitDetails";

interface IProps {
    goals: ICleaningGoal[]
}

const GoodsDisplay = (props: IProps) => {

    const appContext = useContext(AppContext);

    const resource = UnitDetailsTranslations;
    const culture = appContext.selectedCulture!.code;

    if (props.goals.length === 0) {
        return (
            <div style={{ marginLeft: "1.4rem" }}>
                <ul>
                    <li>{resource[culture].noGoals}</li>
                </ul>
            </div>
        )

    } else {
        return (
            <div className="col-md-8 col-xs-12 col-sm-12 col-xl-4" style={{marginLeft: "-15px"}} >
                <h3>{resource[culture].cleaningGoals}</h3>
                <table className="table">
                <tbody>
                    {props.goals.map(goal =>
                        <tr>
                            <td width="70%">{goal.cleaningTypeName}:</td>
                            <td align="left">{goal.result}/{goal.goal}</td>
                        </tr>
                    )}
                </tbody>
                </table>
            </div>
        )
    }
}

export default GoodsDisplay;