import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ICleaningDisplay } from '../../domain/cleaning/ICleaningDisplay';
import UnitDetailsTranslations from '../../resources/unit/UnitDetails';
import { AppContext } from '../../context/AppContext';
import { DateFormatter } from '../../utils/Dateutils';

interface IProps {
    handleClose: () => void,
    open: boolean,
    data: ICleaningDisplay | null
}

const useStyles = makeStyles({
  root: {
    width:"100%",
    marginBottom: "1rem",
    padding: "1rem"
  },
  title: {
    margin: "0px 60px 0px 30px",

  },
  pos: {
    
  },
});


export default function CleaningDialog(props: IProps) {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const resource = UnitDetailsTranslations;
  const culture = appContext.selectedCulture!.code;

  const body = (
    <div>
        <DialogTitle id="alert-dialog-title" >{resource[culture].entry}</DialogTitle>
        {props.data === null ?
            <></>
            : 
            <>
              <DialogContent className={classes.title}>
                <dt>{resource[culture].date}</dt> 
                <dd>{DateFormatter(new Date(props.data.recordedAt))}</dd>
                <dt>{resource[culture].cleantype}</dt> 
                <dd>{props.data.cleaningTypeName}</dd>
                <dt>{resource[culture].name}</dt> 
                <dd>{props.data.appUserFullNames}</dd>
                
                {props.data.comment ? 
                <>
                    <dt>{resource[culture].comment}</dt> 
                    <dd>{props.data.comment!}</dd>   
                </>
                :
                <></>
                }
                <DialogActions>
                  <Button onClick={props.handleClose} className={classes.pos} variant="outlined" color="secondary">{resource[culture].close}</Button>
                </DialogActions>
              </DialogContent>
          </>
        }
    </div>
  );

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.root}
      >
        {body}
      </Dialog>
    </div>
  );
}
