import { ICulture } from '../domain/ICulture';
import Axios from 'axios';
import { ICleaning } from '../domain/cleaning/ICleaning';
import { ICleaningCreate } from '../domain/cleaning/ICleaningCreate';
import { ILatestCleaning } from '../domain/cleaning/ILatestCleaning';
import { ILatestCleaningDisplay } from '../domain/cleaning/ILatestCleaningDisplay';

export abstract class CleaningApi {

    private static axios = Axios.create(
        {
            baseURL: process.env.REACT_APP_URLPATH + "Cleanings",
            headers: {
                    'Content-Type': 'application/json'
                
            }
        }
    );

    static async getAll(unitId: number, companyId: number, jwt: string, culture: ICulture): Promise<ICleaning[]> {
        const url = "";
        try {
            const response = await this.axios.get<ICleaning[]>(
                url,
                {
                    params: {
                        unitId: unitId,
                        companyId: companyId,
                        culture: culture.code
                    },
                    headers: {
                        Authorization: "Bearer " + jwt
                    }
                });
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.log("error: ", (error as Error).message);
            return [];
        }
    }

    static async getLatest(companyId: number, jwt: string, culture: ICulture): Promise<ILatestCleaning[]> {
        const url = "/latest";
        try {
            const response = await this.axios.get<ILatestCleaning[]>(
                url,
                {
                    params: {
                        companyId: companyId,
                        culture: culture.code
                    },
                    headers: {
                        Authorization: "Bearer " + jwt
                    }
                });
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            //console.log("error: ", (error as Error).message);
            return [];
        }
    }

    static async getSearchParams(companyId: number, jwt: string, culture: ICulture): Promise<string[]> {
        const url = "/search";
        try {
            const response = await this.axios.get<string[]>(
                url,
                {
                    params: {
                        companyId: companyId,
                        culture: culture.code
                    },
                    headers: {
                        Authorization: "Bearer " + jwt
                    }
                });
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            //console.log("error: ", (error as Error).message);
            return [];
        }
    }

    static async searchCleanings(filters: any, companyId: number, jwt: string, culture: ICulture): Promise<ILatestCleaningDisplay[]> {
        const url = "/search";
        try {
            const response = await this.axios.post(
                url,
                filters,
                {
                    params: {
                        companyId: companyId,
                        culture: culture.code
                    },
                    headers: {
                        Authorization: "Bearer " + jwt
                    }
                });
            if (response.status === 200) {
                return response.data;
            }
            return[];
        } catch (error) {
            return[];
        }
    }


    static async createNew(jwt: string, cleaning: ICleaningCreate, culture: ICulture): Promise<ICleaning | null> {
        const url = "";
        try {
            const response = await this.axios.post<ICleaning>(
                url, 
                cleaning, 
                { 
                    params: {
                        culture: culture.code 
                    }, 
                    headers: { 
                        Authorization: "Bearer " + jwt 
                    } 
                });

            if (response.status === 200) {
                return response.data;
            }
            return response.data;
        } catch (error) {
            console.log(error);
        }
        return null;
    }
}