import { ICulture } from '../domain/ICulture';
import { IMaintenanceLogRow } from '../domain/maintenancelogrow/IMaintenanceLogRow';
import Axios from 'axios';
import { IMaintenanceLogRowCreate } from '../domain/maintenancelogrow/IMaintenanceLogRowCreate';

export abstract class MaintenanceLogRowApi {

    private static axios = Axios.create(
        {
            baseURL: process.env.REACT_APP_URLPATH + "MaintenanceLogRows",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    static async getAll(jwt: string, maintenanceLogId: number, culture: ICulture): Promise<IMaintenanceLogRow[]> {
        const url = "";
        try {
            const response = await this.axios.get<IMaintenanceLogRow[]>(url, { 
                params: { maintenanceLogId: maintenanceLogId, culture: culture.code }, 
                headers: { Authorization: "Bearer " + jwt } });
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            // console.log("error: ", (error as Error).message);
            return [];
        }
    }

    static async createNew(jwt: string, maintenanceLogId: number, maintenanceLogRow: IMaintenanceLogRowCreate, culture: ICulture): Promise<void> {
        const url = "";
        try {
            const response = await this.axios.post(
                url,
                maintenanceLogRow,
                {
                    params: { maintenanceLogId: maintenanceLogId, culture: culture.code },
                    headers: { Authorization: "Bearer " + jwt }
                });
                
            if (response.status === 200) {
                return;
            }
            return;
        } catch (error) {
            // console.log('error: ', (error as Error).message);
        }
    }

    static async getDetails(jwt: string, id: number): Promise<IMaintenanceLogRow | null> {
        const url = "" + id;
        try {
            const response = await this.axios.get<IMaintenanceLogRow>(url, { headers: { Authorization: "Bearer " + jwt } });
            if (response.status === 200) {
                return response.data;
            }
            return null;
        } catch (error) {
            // console.log('error: ', (error as Error).message);
            return null;
        }
    }

}