import React, { useEffect, useState, useContext } from "react";
import { IWarehouse } from "../../domain/warehouse/IWarehouse";
import { WarehouseApi } from "../../services/WarehouseApi";
import { UnitsApi } from "../../services/UnitsApi";
import { AppContext } from "../../context/AppContext";
import UnitsForWarehouse from "./UnitsForWarehouse";
import UnitForWarehouseTranslations from "../../resources/warehouses/UnitsForWarehouse";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: "1rem",
        padding: "1rem"
      },
      title: {
        fontSize: 14,
      },
      pos: {
        marginBottom: 12,
      },
  });



const WarehouseIndex = () => {
    const appContext = useContext(AppContext);
    const [warehouses, setWarehouses] = useState([] as IWarehouse[]);
    const [isLoading, setIsLoading] = useState(true as boolean);
    const resource = UnitForWarehouseTranslations;
    const culture = appContext.selectedCulture!.code;
    const classes = useStyles();

    useEffect(() => {
        setIsLoading(true);
        const callApi = async () => {
            const data = await WarehouseApi.getAll(appContext.companyId!, appContext.selectedCulture!, appContext.jwt!);
            data.sort((a, b) => a.sortingNumber - b.sortingNumber);
            for (const warehouse of data) {
                    warehouse.units = await UnitsApi.getAll(warehouse.id, appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
                    warehouse.units.sort((a, b) => a.sortingNumber - b.sortingNumber);
            }
            setWarehouses(data);
        };
        callApi()
            .then(() => setIsLoading(false));
        
    }, [appContext.companyId, appContext.selectedCulture, appContext.jwt]);
    
    return (
        <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation"}>
            <Card className={classes.root}>
             <Typography variant="h3" component="h2">
                    {resource[culture].warehouses}
                </Typography>
            </Card>
            {isLoading
            ? 
                <></>
            :
                (   
                    warehouses.map((warehouse, index) => {
                        return (
                            <div key={warehouse.id}>
                                <UnitsForWarehouse units={warehouse.units} warehouseId={warehouse.id} index={index} name={warehouse.name} />
                            </div>
                        )}
                    )
                ) 
            }    
        </div>
        
    )
}

export default WarehouseIndex;