export interface ILayoutResourceStrings {
    name: string,
    view: string,
    warehouses: string,
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const UnitForWarehouseTranslations: ILayoutResources = {
    [engb]: {
        name: "Name",
        view: "View",
        warehouses: "Warehouses"
    },
    [etee]: {
        name: "Nimi",
        view: "Vaata",
        warehouses: "Laohooned"
    },
}

export default UnitForWarehouseTranslations;