import React, { useState } from "react";
import FormView from "./FormView";

export interface IFormState {
    input: string;
    checkbox: boolean;
    select: string;
    radioGroup1: boolean[];
    textarea: string;
}

const Form = () => {
    const [state, setState] = useState(
        {
            input: "",
            checkbox: true,
            select: "2",
            radioGroup1: [false, true, false],
            textarea:'foo\nbar'
        } as IFormState
    );
    const handleChange = (target: 
        EventTarget & HTMLInputElement | 
        EventTarget & HTMLSelectElement |
        EventTarget & HTMLTextAreaElement
        ) => {
            console.log(target.name, target.value, target.type, target);
            //causes a breakpoint
            //debugger;
            if (target.type === "text") {
                setState({...state, [target.name]: target.value });
            }

            if (target.type === "checkbox" && target instanceof HTMLInputElement) {
                setState({...state, [target.name]: target.checked });
            }

            if (target.type === "select-one" && target instanceof HTMLSelectElement) {
                setState({...state, [target.name]: target.value});
            }

            if (target.type === "radio" ) {
                const newState = {...state};
                newState.radioGroup1.forEach((item, index, array) => array[index] = false);
                newState.radioGroup1[Number(target.value)] = true;
                setState(newState);
            }
    }

    return (
        <div className="container-fluid">
            <h1>Form</h1>
            <FormView data={state} handleChange={handleChange}/>
        </div>
    );
}

export default Form;