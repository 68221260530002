import { FormEvent, useContext, useState } from "react";
import { IWarehouse } from "../../domain/warehouse/IWarehouse";
import { AppContext } from "../../context/AppContext";
import { ICleaningType } from "../../domain/cleaningtype/ICleaningType";
import React from "react";
import { ICleaningState } from "./AddCleaning";
import BackButton from "../shared/BackButton";
import CleaningFormTranslations from "../../resources/cleaning/CleaningForm";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Button, Collapse, makeStyles } from "@material-ui/core";
import { IUserDTO } from "../../domain/user/IUserDTO";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';




interface IProps {
    data: ICleaningState;
    handleChange: (target:
        EventTarget & HTMLInputElement |
        EventTarget & HTMLSelectElement
    ) => void;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    warehouses: IWarehouse[],
    cleaningTypes: ICleaningType[],
    setSelectedUsersState: (users: IUserDTO[]) => void;
    users: IUserDTO[],
    selectedUsers: IUserDTO[],
    addBtnDisabled: boolean
}


const useStyles = makeStyles({
    root: {
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
      marginBottom: "12px",
      textAlign: "center"

    },
    btn: {
        width: "100%",
        marginBottom: "0.5rem"
    },
    btnmargin: {
        marginBottom: "0.5rem",
        marginRight: "0.5rem"
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    }
  });

const CleaningForm = (props: IProps) => {
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const resource = CleaningFormTranslations;
    const culture = appContext.selectedCulture!.code;
    const [addUser, setAddUser] = useState(false as boolean);
    const [addComment, setAddComment] = useState(false as boolean);

    return (
        <>
        <div className="row">
        <div className="col-sm-10 col-xs-12 col-md-6 col-lg-6 col-xl-4 mx-auto">

            <Card className={classes.root}>
            <Typography className={classes.title} variant="h4" component="h4">
                {resource[culture].add}
            </Typography>

                    <form className="form-signin" onSubmit={props.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">{resource[culture].name}</label>
                            <input value={appContext.firstName! + " " + appContext.lastName!} className="form-control" name="name" onChange={(e) => props.handleChange(e.target)} type="email" disabled />
                        </div>
                        <Button variant="outlined" color="primary" className={classes.btnmargin} onClick={() => setAddUser(prevShow => !prevShow)}>{resource[culture].addUsers}</Button>
                        <Button variant="outlined" color="primary" className={classes.btnmargin} onClick={() => setAddComment(prevShow => !prevShow)}>{resource[culture].addComment}</Button>
                        <Collapse in={addUser} className="col align-self-center" style={{padding: "0px"}}>
                        <div className="form-group">
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            id="user-add"
                            options={props.users}
                            classes={{
                                option: classes.option,
                            }}
                            onChange={(event, value) => props.setSelectedUsersState(value)}
                            autoHighlight
                            getOptionLabel={(option: IUserDTO) => option.firstName + " " + option.lastName}
                            renderOption={(option: IUserDTO) => (
                                <React.Fragment>
                                {option.firstName} {option.lastName}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <>
                                <label htmlFor="name">{resource[culture].name}</label>
                                <TextField
                                {...params}
                                variant="outlined"
                                placeholder={resource[culture].userName}
                                inputProps={{
                                    ...params.inputProps,
                                    
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                />
                                </>
                            )}
                        />
                        </div>
                        </Collapse>

                        <Collapse in={addComment} className="col align-self-center" style={{padding: "0px"}}>
                        <div className="form-group">
                            <label htmlFor="comment">{resource[culture].comment}</label>
                            <input value={props.data.comment!} className="form-control" name="comment" type="text" onChange={(e) => props.handleChange(e.target)} id="comment" placeholder={resource[culture].commentplaceholder} />
                            <div style={{fontSize: 12, color: "red"}}>{props.data.commentError}</div>
                        </div>
                        </Collapse>
                        <div className="form-group">
                            <label htmlFor="unitId">{resource[culture].units}</label>
                            <select defaultValue={""} name="unitId" onChange={(e) => props.handleChange(e.target)} className="form-control" id="unitId">
                                <option key={1} value=""> -- {resource[culture].selectUnit} --</option>

                                {
                                    props.warehouses.map((warehouse, i) => {
                                        return (
                                        <>
                                            <optgroup key={i} label={warehouse.name}>
                                            {
                                                warehouse.units.map((item, index) => (
                                                    <option value={item.id} key={index}>{item.name}</option>
                                                ))
                                            }
                                            </optgroup>
                                        </>
                                        )
                                    })
                                }
                            </select>
                            <div style={{fontSize: 12, color: "red"}}>{props.data.unitError}</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="cleaningTypeId">{resource[culture].cleaningtype}</label>
                            <select defaultValue={""} name="cleaningTypeId" onChange={(e) => props.handleChange(e.target)} className="form-control" id="cleaningTypeId">
                                <option value="" key={1}> -- {resource[culture].selectType} --</option>
                                {
                                    props.cleaningTypes.map((item, index) => (
                                        <option value={item.id} key={index}>{item.name}</option>
                                    ))
                                }
                            </select>
                            <div style={{fontSize: 12, color: "red"}}>{props.data.cleaningTypeError}</div>
                        </div>
                        <div style={{marginBottom:"2rem"}}>
                            <Button variant="outlined" disabled={props.addBtnDisabled} color="primary" className={classes.btn} type="submit">{resource[culture].addbtn}</Button>
                            <BackButton classDescription={"btn btn-lg btn-primary btn-block"} btnString={resource[culture].back}/>
                        </div>
                    </form>
            </Card>
            </div>
            </div>
        </>
    )

}

export default CleaningForm;