import { Paper, TableContainer } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { ILatestMaintenanceLogEntry } from "../../domain/maintenancelogentry/ILatestMaintenanceLogEntry";
import MaintenanceLogIndexTranslations from "../../resources/maintenancelog/MaintenanceLogIndex";
import { DateFormatter } from "../../utils/Dateutils";

interface IProps {
    entries: ILatestMaintenanceLogEntry[]
}

const LatesMaintenanceLogEntriesDisplay = (props: IProps) => {
    const appContext = useContext(AppContext);

    const resource = MaintenanceLogIndexTranslations;
    const culture = appContext.selectedCulture!.code;

    return (
        <>
        <TableContainer component={Paper}>
        <Table aria-label="simple table">
        <TableHead>
            <TableRow>
                    <TableCell align="right">{resource[culture].date}</TableCell>
                    <TableCell>{resource[culture].user}</TableCell>
                    <TableCell>{resource[culture].log}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
                {props.entries.map((entry, index) =>
                <TableRow className="log-row" key={index}>
                    <TableCell align="right">{DateFormatter(new Date(entry.recordedAt))}</TableCell>
                    <TableCell>{entry.appUserFullName}</TableCell>
                    <TableCell>{entry.maintenanceLogName}</TableCell>
                </TableRow>
                )}
        </TableBody>
        </Table>
        </TableContainer>
        </>
    )
}

export default LatesMaintenanceLogEntriesDisplay;