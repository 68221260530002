import { ICulture } from '../domain/ICulture';
import React from "react";

export interface IAppContext {
    jwt: string | null;
    userName: string;
    companyId: number | null;
    roleName: string | null;
    firstName: string | null;
    lastName: string | null;
    selectedCulture: ICulture | null;
    setJwt: (jwt: string) => void;
    setUserName: (userName: string) => void;
    setCompanyId: (companyId: number) => void;
    setRoleName: (roleName: string) => void;

    setCulture: (culture: ICulture) => void;
    setAll: (jwt: string, userName: string, companyId: number, roleName: string, firstName: string, lastName: string) => void;
    clearAll: () => void;
}

export const AppContextInitialState : IAppContext = {
    jwt: null,
    userName: "",
    companyId: null,
    roleName: "",
    firstName: "",
    lastName: "",
    selectedCulture: { code: "et-EE", name: "Eesti EE" },
    setJwt: (a) => {},
    setUserName: (x) => {},
    setCompanyId: (x) => {},
    setRoleName: (x) => {},
    setCulture: (x) => {},
    setAll: (a, b, c, d, e, f) => {},
    clearAll: () => {},
}

export const AppContext = React.createContext<IAppContext>(AppContextInitialState);

export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;



