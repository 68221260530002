import React, { useContext } from "react";
import HomeTranslations, { ILayoutResources } from "../../resources/shared/Home"
import Card from '@material-ui/core/Card';
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

interface IProps {
    resource: ILayoutResources
    culture: string
}
interface IProps {
  resource: ILayoutResources
  culture: string
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: "1rem",
    padding: "1rem"
  },
  title: {
    marginBottom: "12px"
  },
  pos: {
    marginTop: "20px",
    width: "100%",
    height: "4rem",
    backgroundColor: '#ffffff',
    borderColor: '#007bff',
    color: "#007bff",
  '&:hover': {
    backgroundColor: "#e1e8f0",
    borderColor: '#003e81',
    color: "#003e81",
    
  },
},
});

const DefaultHome = (props: IProps) => {
  const classes = useStyles();
  const resource = HomeTranslations;
  const appContext = useContext(AppContext);
  const culture = appContext.selectedCulture!.code;

    return(
        <>
        <div className="container div-onload-animation">

        <Card className={classes.root}>
        <div className="row justify-content-center">
              <div className="col-md-6" style={{padding: "2rem"}}>
              <p><Link to="/Login" className="btn btn-primary btn-lg" style={{ background: "Red", border: "Red", width: "100%" }} >{resource[culture].logIn} &raquo;</Link></p>
              </div>
            </div>
            </Card>
          </div>
        </>
    )
}

export default DefaultHome;