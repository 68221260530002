export interface ILayoutResourceStrings {
    maintenanceLog: string,
    maintenanceLogs: string,
    aboutText: string,
    emailText: string,
    bugText: string,
    search: string,
    viewLogs: string,
    warehouses: string,
    addCleaning: string,
    viewLatest: string,
    logIn: string,
    viewWarehouses: string,
    headerTitle: string,
    betterOverview: string,
    viewDetails: string,
    greeting: string,
    transparency: string,
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}
const engb = "en-GB";
const etee = "et-EE";

export const HomeTranslations: ILayoutResources = {
    [engb]: {
        maintenanceLog: "Equipment maintenance logs",
        maintenanceLogs: "Maintenance logs",
        aboutText: "Warehouse cleaning and equipment maintenance log for Katoen Natie.",
        emailText: "Write or send a screenshot to my E-mail at ",
        bugText: "Noticed a bug?",
        search: "Search for cleanings",
        viewLogs: "Maintenance logs",
        warehouses: "Warehouses",
        addCleaning: "Add cleaning",
        viewLatest: "View latest cleanings",
        logIn: "Log in",
        viewWarehouses: "View warehouses",
        headerTitle: "Katoen Natie maintenance log",
        betterOverview: "Better overview",
        viewDetails: "View Details",
        greeting: "Hey",
        transparency: "Transparency"
    },
    [etee]: {
        maintenanceLog: "Logiraamatud",
        maintenanceLogs: "Logiraamatud",
        aboutText: "Katoen Natie ladude ja tehnika hoolduslogide süsteem.",
        emailText: "Kirjuta või saada kuvatõmmis mulle meilile:",
        bugText: "Märkasid viga?",
        search: "Otsi koristamisi",
        viewLogs: "Vaata halduslogisid",
        warehouses: "Laod",
        addCleaning: "Lisa koristus",
        viewLatest: "Viimased koristamised",
        logIn: "Logi sisse",
        viewWarehouses: "Vaata ladusid",
        headerTitle: "Katoen Natie laopäevik",
        betterOverview: "Parem ülevaade",
        viewDetails: "Loe lähemalt",
        greeting: "Tere",
        transparency: "Läbipaistvus"
    },
}

export default HomeTranslations;