export interface ILayoutResourceStrings {
    name: string,
    title: string,
    addTitle: string,
    addbtn: string,
    comment: string,
    back: string,
    logrow: string,
    hoursWorked: string,
    hoursWorkedAlert: string,
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const MaintenanceLogEntryFormTranslations: ILayoutResources = {
    [engb]: {
        name: "Name",
        title: "Create a new maintenance log",
        addTitle: "Create a new maintenance log entry",
        addbtn: "Add",
        comment: "Add a comment",
        back: "Back",
        logrow: "Log row",
        hoursWorked: "Number of hours",
        hoursWorkedAlert: "Number of hours has not been specified!"

    },
    [etee]: {
        name: "Nimi",
        title: "Lisa uus halduslogi",
        addTitle: "Lisa uus hoolduslogi sissekanne",
        addbtn: "Lisa",
        comment: "Lisa kommentaar",
        back: "Tagasi",
        logrow: "Logi rida",
        hoursWorked: "Tundide arv",
        hoursWorkedAlert: "Töötundide arvu pole märgitud!"
    },
}
export default MaintenanceLogEntryFormTranslations;