import React, { useState, useEffect, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { IUserProfile } from "../../domain/user/IUserProfile";
import { UserApi } from "../../services/UserApi";
import { AppContext } from "../../context/AppContext";
import ProfileTranslations from "../../resources/user/Profile";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    title: {
      paddingBottom: "15px"
    },
    pos: {
      marginBottom: 12,
    },
  });

const Profile = () => {
    const [userProfile, setUserProfile] = useState({} as IUserProfile);
    const appContext = useContext(AppContext);
    const history = useHistory();
    const resource = ProfileTranslations;
    const culture = appContext.selectedCulture!.code;
    const classes = useStyles();

    useEffect(() => {
        const callApi = async () => {
            if (appContext.jwt == null){
                history.push("/");
            }
            const data = await UserApi.getProfile(appContext.jwt!);
            if (data == null){
                history.push("/");
            }
            setUserProfile(data!);
        };
        callApi();

    }, [appContext.jwt, history, appContext.selectedCulture])

    return (
    <div className="container-fluid">
        <Card className={classes.root}>
            <CardContent>
            <Typography className={classes.title} variant="h3" component="h2">
                {resource[culture].profile}
            </Typography>
            <dl className="row">
                <dt className="col-sm-4">{resource[culture].firstname}</dt>
                <dd className="col-sm-8">{ userProfile.firstName }</dd>
              
                <dt className="col-sm-4">{resource[culture].lastname}</dt>
                <dd className="col-sm-8">{ userProfile.lastName }</dd>
                
                <dt className="col-sm-4">{resource[culture].email}</dt>
                <dd className="col-sm-8">{ userProfile.email }</dd>

                <dt className="col-sm-4">{resource[culture].role}</dt>
                <dd className="col-sm-8">{ appContext.roleName }</dd>
            </dl>
            <NavLink to="/" className="btn btn-primary">{resource[culture].home}</NavLink>
            </CardContent>
        </Card>

        {/* A JSX comment 
        <div>
        <br />
        <div className="row">
        <div className="col-md-4">
        <form>
            <div className="form-group">
                <label className="control-label" htmlFor="firstName">First name</label>
                <input className="form-control" type="text" id="firstName"/>
            </div>
            <div className="form-group">
                <label className="control-label" htmlFor="lastName">Last name</label>
                <input className="form-control" type="text" id="lastName"/>
            </div> 
            <div className="form-group">
                <label className="control-label" htmlFor="email">Email</label>
                <input disabled className="form-control" type="text" id="email"/> 
            </div>

            <div className="form-group">
                <button type="button" className="btn btn-lg btn-primary btn-block">Update</button>
            </div>
        </form>
        </div>
        </div>
        </div>
        */}
    </div>
    )
}

export default Profile;