import React, { useContext, FormEvent } from "react";
import { AppContext } from "../../context/AppContext";
import { IProduct } from "../../domain/product/IProduct";
import { IProductInUnitCreate } from "../../domain/productinunit/IProductInUnitCreate";
import BackButton from "../shared/BackButton";
import AddGoodsFormTranslations from "../../resources/unit/AddGoodsForm";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Button, makeStyles } from "@material-ui/core";

interface IProps {
    data: IProductInUnitCreate;
    productError: string;
    products: IProduct[]
    handleChange: (target: EventTarget & HTMLSelectElement) => void;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}



const useStyles = makeStyles({
    root: {
      minWidth: 275,
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
        marginBottom: "12px"
    },
    btn: {
      width: "100%",
      marginBottom: "0.5rem"
    },
  });

const AddGoodsForm = (props: IProps) => {
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const resource = AddGoodsFormTranslations;
    const culture = appContext.selectedCulture!.code;


    return (
        <>
        <div className="row">
        <div className="col-sm-10 col-xs-12 col-md-10 col-lg-8 col-xl-6 mx-auto">
        <Card className={classes.root}>
            <Typography className={classes.title} variant="h3" component="h2">
                {resource[culture].title}
            </Typography>
            <form className="form-addgoods" onSubmit={props.handleSubmit}>    
        <div className="form-group">
            <label htmlFor="exampleFormControlSelect1">{resource[culture].product}</label>
            <select value={props.data.productId} name="productId" onChange={(e) => props.handleChange(e.target)} className="form-control" id="exampleFormControlSelect1">
                <option value="" key={1}>-- {resource[culture].selectproduct} --</option>
                {
                    props.products.map((item, index) => (
                        <option value={item.id} key={index}>{item.name}</option>
                    ))
                }

            </select>
            <div style={{ fontSize: 12, color: "red" }}>{props.productError}</div>
        </div>
        <Button variant="outlined" color="primary" className={classes.btn} type="submit">{resource[culture].add}</Button>
        <BackButton classDescription="btn btn-lg btn-primary btn-block" btnString={resource[culture].back}/>
        </form>
        </Card>
        </div>
        </div>
        </>
    )

}

export default AddGoodsForm;