import { ICulture } from '../domain/ICulture';
import Axios from 'axios';
import { ICleaningType } from '../domain/cleaningtype/ICleaningType';

export abstract class CleaningTypeApi {
    
    private static axios = Axios.create(
        {             
            baseURL: process.env.REACT_APP_URLPATH + "CleaningTypes",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    static async getAll(companyId: number, jwt: string, culture: ICulture): Promise<ICleaningType[]> {
        const url = "";
        try {
            const response = await this.axios.get<ICleaningType[]>(url, { params: {companyId: companyId, culture: culture.code}, headers: { Authorization: "Bearer " + jwt }});
            //console.log("getAll Response", response);
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.log("error: ", (error as Error).message);
            return [];
        }
    }

    static async getDetails(jwt: string, id: number, culture: ICulture): Promise<ICleaningType | null> {
        const url = "/" + id;
        try {
            const response = await this.axios.get<ICleaningType>(url, { params: {id: id, culture: culture.code}, headers: { Authorization: "Bearer " + jwt } });
            //console.log('getDetails response', response);
            if (response.status === 200) {
                return response.data;
            }
            return null;
        } catch (error) {
            console.log('error: ', (error as Error).message);
            return null;
        }
    }
}