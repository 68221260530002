import React, { FormEvent, useContext, useEffect, useState } from "react";
import CleaningForm from "./CleaningForm";
import { AppContext } from "../../context/AppContext";
import { CleaningApi } from "../../services/CleaningApi";
import { useHistory } from "react-router-dom";
import { ICleaningType } from "../../domain/cleaningtype/ICleaningType";
import { IWarehouse } from "../../domain/warehouse/IWarehouse";
import { CleaningTypeApi } from "../../services/CleaningTypeApi";
import { WarehouseApi } from "../../services/WarehouseApi";
import { UnitsApi } from "../../services/UnitsApi";
import { IUserDTO } from "../../domain/user/IUserDTO";
import { UserApi } from "../../services/UserApi";
import { UserInCleaningApi } from "../../services/UserInCleaningApi";

export interface ICleaningState {
    comment?: string | null;
    unitId: number;
    cleaningTypeId: number;
    commentError: string;
    unitError: string;
    cleaningTypeError: string;
}

const initialState = {
    comment: "",
    unitId: 0,
    cleaningTypeId: 0,
    commentError: "",
    unitError: "",
    cleaningTypeError: ""
}

const AddCleaning = () => {
    const appContext = useContext(AppContext);

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true as boolean);

    const [state, setState] = useState(initialState as ICleaningState);
    const [selectedUsers, setSelectedUsers] = useState([] as IUserDTO[]);
    
    const [cleaningTypes, setCleaningTypes] = useState([] as ICleaningType[]);
    const [warehouses, setWarehouses] = useState([] as IWarehouse[]);
    const [users, setUsers] = useState([] as IUserDTO[]);
    const [addBtnDisabled, setAddBtnDisabled] = useState(false);

    useEffect(() => {
        const callApis = async () => {
            const cleaningTypesData = await CleaningTypeApi.getAll(appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
            setCleaningTypes(cleaningTypesData);
            
            const warehousesData = await WarehouseApi.getAll(appContext.companyId!, appContext.selectedCulture!, appContext.jwt!);
            for (var warehouse of warehousesData) {
                warehouse.units = await UnitsApi.getAll(warehouse.id, appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
                warehouse.units.sort((a, b) => a.sortingNumber - b.sortingNumber);
            }
            warehousesData.sort((a, b) => a.sortingNumber - b.sortingNumber);
            setWarehouses(warehousesData);
            
            const usersData = await UserApi.getAllUsers(appContext.jwt!, appContext.companyId!);
            if (usersData) {
                setUsers(usersData);
            }
        };
        callApis()
         .then(() => setIsLoading(false));

    }, [appContext.companyId, appContext.jwt, appContext.selectedCulture])

    const handleChange = (target:
        EventTarget & HTMLInputElement |
        EventTarget & HTMLSelectElement |
        EventTarget & HTMLTextAreaElement
    ) => {
        if (target.type === "text" && target.name === "comment") {
            setState({ ...state, [target.name]: target.value });
        }

        if (target.type === "unit" && target instanceof HTMLSelectElement) {
            setState({ ...state, [target.name]: target.value });
        }
        if (target.type === "select-one" && target instanceof HTMLSelectElement) {
            setState({ ...state, [target.name]: target.value });
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValid = validation();
        if (isValid) {
            setAddBtnDisabled(addBtnDisabled => !addBtnDisabled);
            let cleaning = await CleaningApi.createNew(appContext.jwt!, { comment: state.comment!, unitId: state.unitId, cleaningTypeId: state.cleaningTypeId }, appContext.selectedCulture!);
            if (cleaning !== null) {
                for (var u of selectedUsers) {
                    await UserInCleaningApi.createNew(appContext.jwt!, { appUserId: u.id, cleaningId: cleaning!.id });
                }
            }
            // eslint-disable-next-line
            history.push("Warehouses/" + warehouses.find(x => x.units.find(u => u.id == state.unitId))!.id + "/Units/" + state.unitId);
        } 
    }

    const setSelectedUsersState = (users: IUserDTO[]) => {
        setSelectedUsers(users);
    }

    const validation = () => {
        let cte = "";
        let ue = "";
        let ce = "";
        let valid = true;
        if (state.unitId === 0) {
            valid = false;
            ue =  "Select unit";
        } else {
            ue = "";
        }

        if (state.cleaningTypeId === 0) {
            valid = false;
            cte = "Select cleaning type";
        }
        else {
            cte = "";
        }

        if (state.comment) {
            if (state.comment.length > 128) {
                valid = false;
                ce = "Comment is too long!";
            } else {
                ce = "";
            }
        }
        setState({ ...state, unitError:  ue, cleaningTypeError: cte, commentError: ce});

        return valid;
    }

    
    return (
        <>
        <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation"}>
            <CleaningForm data={state} addBtnDisabled={addBtnDisabled} handleChange={handleChange} setSelectedUsersState={setSelectedUsersState} handleSubmit={handleSubmit} users={users} selectedUsers={selectedUsers} 
            warehouses={warehouses} cleaningTypes={cleaningTypes} />
        </div>
        </>
    )

}

export default AddCleaning;