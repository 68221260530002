import React, { useContext, useState, useEffect } from "react"
import { NavLink, useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { ICulture } from "../../domain/ICulture";
import { CultureApi } from "../../services/CultureApi";
import { Layout } from "../../resources/shared/Layout";

const Header = () => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [cultures, setCultures] = useState([] as ICulture[]);
  const resource = Layout;
  const culture = appContext.selectedCulture!.code;

  useEffect(() => {
    const callApi = async () => {
      const data = await CultureApi.getAll();
      if (data) {
        setCultures(data);
      }
    };
    callApi();
  }, [appContext.selectedCulture])


  const setLanguage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, culture: ICulture) => {
    e.preventDefault();
    appContext.setCulture(culture);
  }


  const logOut = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    localStorage.clear();
    history.push("/Login");
    window.location.reload();

  }
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
        <NavLink to="/" className="navbar-brand font-weight-normal">KTN</NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink to="/" activeClassName="active" className="nav-link">
                <span className="fa fa-home" aria-hidden="true"> </span> {resource[culture].home}
              </NavLink>
            </li>
            {appContext.userName && appContext.jwt ? (
            <>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink to="/Warehouses" activeClassName="active" className="nav-link">
                <span className="fa fa-list" aria-hidden="true"> </span> {resource[culture].warehouses}
              </NavLink>
            </li>

            {appContext.roleName === "admin" || appContext.roleName === "employee" ? 
              <>
               <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink to="/Cleaning" activeClassName="active" className="nav-link">
                <span className="fa fa-plus" aria-hidden="true"> </span> {resource[culture].addCleaning}

              </NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink to="/MaintenanceLogs" activeClassName="active" className="nav-link">
                <span className="fa fa-edit" aria-hidden="true"> </span> {resource[culture].maintenanceLog}
              </NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink to="/CleaningSearch" activeClassName="active" className="nav-link">
                <span className="fa fa-search" aria-hidden="true"> </span> {resource[culture].search}
              </NavLink>
            </li>
              </>  : <></>
            }
            </>
            ) : (<></>)
            }
            {cultures.length > 0 ? (
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href={"null"} onClick={(e) => {e.preventDefault()}} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="fa fa-language" > </span> {resource[culture].language}
                </a>
                <div className="nav-item dropdown-menu" aria-labelledby="navbarDropdown">
                  {
                    cultures.map((culture, index) =>
                      <button key={index} data-toggle="collapse" data-target=".navbar-collapse.show" className="dropdown-item" onClick={e => {setLanguage(e, culture)}} >{culture.name}</button>
                    )
                  }
                </div>
              </li>
            ): (<></>)
            }
          </ul>
          {appContext.userName && appContext.jwt ? (
            <ul className="navbar-nav">
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <NavLink to="/Profile" activeClassName="active" className="nav-link">
                  <span className="fa fa-user" aria-hidden="true"> </span> {appContext.userName}
                </NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <a className="nav-link" href={"null"} onClick={e => logOut(e)}>
                  <span className="fa fa-sign-out" aria-hidden="true"> </span> {resource[culture].logOut}
                </a>
              </li>
            </ul>
          )
            : (

              <ul className="navbar-nav">
                {/* 
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <NavLink to="/Register" activeClassName="active" className="nav-link">Register</NavLink>
                </li>
                */}
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <NavLink to="/Login" className="nav-link" activeClassName="active">
                    <span className="fa fa-sign-in" aria-hidden="true"> </span> {resource[culture].logIn}

                  </NavLink>
                </li>
              </ul>
            )
          }

        </div>
      </nav>
    </header>
  );
}
export default Header;