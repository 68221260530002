import { ICulture } from '../domain/ICulture';
import { IMaintenanceLog } from '../domain/maintenancelog/IMaintenanceLog';
import Axios from 'axios';
import { IMaintenanceLogCreate } from '../domain/maintenancelog/IMaintenanceLogCreate';

export abstract class MaintenanceLogApi {

    private static axios = Axios.create(
        {
            baseURL: process.env.REACT_APP_URLPATH + "MaintenanceLogs",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    static async getAll(jwt: string, companyId: number, culture: ICulture): Promise<IMaintenanceLog[]> {
        const url = "";
        try {
            const response = await this.axios.get<IMaintenanceLog[]>(url, {
                 params: { companyId: companyId, culture: culture.code }, 
                 headers: { Authorization: "Bearer " + jwt } 
                });
            //console.log("getAll Response", response);
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.log("error: ", (error as Error).message);
            return [];
        }
    }

    static async createNew(jwt: string, maintenanceLog: IMaintenanceLogCreate, culture: ICulture): Promise<IMaintenanceLog | null> {
        const url = "";
        try {
            const response = await this.axios.post(url, maintenanceLog, { 
                params: {culture: culture.code}, 
                headers: { Authorization: "Bearer " + jwt } 
            });
            //console.log('Create response', response);
            if (response.status === 201) {
                return response.data;
            }
            return null;
        } catch (error) {
            console.log('error: ', (error as Error).message);
            return null;
        }
    }

    static async getDetails(jwt: string, id: number, culture: ICulture): Promise<IMaintenanceLog | null> {
        const url = "" + id;
        try {
            const response = await this.axios.get<IMaintenanceLog>(url, {
                 params: { culture: culture.code }, 
                 headers: { Authorization: "Bearer " + jwt } 
                });
            //console.log('getDetails response', response);
            if (response.status === 200) {
                return response.data;
            }
            return null;
        } catch (error) {
            console.log('error: ', (error as Error).message);
            return null;
        }
    }

    static async delete(id: number, jwt: string): Promise<void> {
        const url = "" + id;
        try {
            const response = await this.axios.delete(url, { headers: { Authorization: "Bearer " + jwt } });
            //console.log('delete response', response);
            if (response.status === 200) {
                return;
            }
            return;
        } catch (error) {
            console.log('error: ', (error as Error).message);
        }
    }


}