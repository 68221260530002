import { IUserFullname } from './../domain/user/IUserFullname';
import { ILoginDTO } from './../domain/user/ILoginDTO';
import  { IUserProfile } from './../domain/user/IUserProfile';
import Axios from "axios";
import { IUserDTO } from '../domain/user/IUserDTO';

interface ILoginResponse {
    token: string;
    status: string;
    companyId: number;
    roleName: string;
    firstName: string;
    lastName: string;
}

export abstract class UserApi {
    private static axios = Axios.create({
        baseURL: process.env.REACT_APP_URLPATH + "User/",
        headers: {
            'Content-Type': 'Application/json'
        }
    });
    
    static async login(loginDTO: ILoginDTO): Promise<ILoginResponse | null> {
        const url = "Login";
        try {
            const response = await this.axios.post<ILoginResponse>(url, loginDTO);
            //console.log('getJwt response', response);
            if (response.status === 200) {
                let token = response.data.token;
                let status = response.data.status;
                let companyId = response.data.companyId;
                let firstName = response.data.firstName;
                let lastName = response.data.lastName;
                let roleName = response.data.roleName;
                return { token, status, companyId, roleName, firstName, lastName };
            }
            return null;
        } catch (error) {
            console.log('error', (error as Error).message);
            return null;
        }
    }

    static async getUserFullname(jwt: string, id: number): Promise<IUserFullname | null> {
        const url = "profile";
        try {
            const response = await this.axios.get<IUserFullname>(url, { params: {id: id}, headers: { Authorization: "Bearer " + jwt } });
            //console.log('getUserFullname response', response);
            if (response.status === 200) {
                //console.log(response.data);
                return response.data;
            }
            return null;
        } catch (error) {
            //console.log('error', (error as Error).message);
            return null;
        }
    }

    static async getAllUsers(jwt: string, companyId: number): Promise<IUserDTO[] | null> {
        const url = "getAllUsers";
        try {
            const response = await this.axios.get<IUserDTO[]>(url, { params: {companyId: companyId}, headers: { Authorization: "Bearer " + jwt } });
            //console.log('getUserFullname response', response);
            if (response.status === 200) {
                //console.log(response.data);
                return response.data;
            }
            return null;
        } catch (error) {
            //console.log('error', (error as Error).message);
            return null;
        }
    }

    static async getProfile(jwt: string): Promise<IUserProfile | null> {
        const url = "profile";
        try {
            const response = await this.axios.get<IUserProfile>(url, { headers: { Authorization: "Bearer " + jwt } });
            //console.log('getProfile response', response);
            if (response.status === 200) {
                // console.log(response.data);
                return response.data;
            }
            return null;
        } catch (error) {
            console.log('error', (error as Error).message);
            return null;
        }
    }

    static async createUser(user: IUserProfile): Promise<ILoginResponse | null> {
        const url = "register";
        try {
            const response = await this.axios.post<ILoginResponse>(url, user);
            //console.log('getProfile response', response);
            if (response.status === 200) {
                // console.log(response.data);
                return response.data as ILoginResponse;
            }
            return null;
        } catch (error) {
            console.log('error', (error as Error).message);
            return null;
        }
    }

    static async updateUserProfile(jwt: string, userProfile: IUserProfile): Promise<IUserProfile | null> {
        const url = "updateprofile";
        try {
            const response = await this.axios.post<IUserProfile>(url, userProfile, { headers: { Authorization: "Bearer " + jwt } });
            //console.log('updateprofile response', response);
            if (response.status === 200) {
                // console.log(response.data);
                return response.data;
            }
            return null;
        } catch (error) {
            console.log('error', (error as Error).message);
            return null;
        }
    }
}
