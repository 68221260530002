import React, { FormEvent, useContext } from "react";
import { IMaintenanceLogState } from "./MaintenanceLogAdd";
import BackButton from "../shared/BackButton";
import { AppContext } from "../../context/AppContext";
import MaintenanceLogFormTranslations from "../../resources/maintenancelog/LogAddForm";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Button, makeStyles } from "@material-ui/core";


interface IProps {
    data: IMaintenanceLogState;
    handleChange: (target:
        EventTarget & HTMLInputElement |
        EventTarget & HTMLSelectElement
    ) => void;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    addRow: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    deleteRow: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void;
    handleText: (target: EventTarget & HTMLInputElement, index: number) => void;
}


const useStyles = makeStyles({
    root: {
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
      marginBottom: "12px",
      textAlign: "center"

    },
    btn: {
        width: "100%",
        marginBottom: "0.5rem"
    },
  });


const MaintenanceLogAddForm = (props: IProps) => {
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const resource = MaintenanceLogFormTranslations;
    const culture = appContext.selectedCulture!.code;

    return (
        <>
        <div className="row">
        <div className="col-sm-10 col-xs-12 col-md-6 col-lg-6 col-xl-4 mx-auto">
        <Card className={classes.root}>
            <Typography className={classes.title} component="h4" variant="h4">
                    {resource[culture].add}
            </Typography>

            <form className="form-signin" onSubmit={props.handleSubmit}>
            <div className="form-group">
                <label htmlFor="comment">{resource[culture].name}</label>
                <input value={props.data.name!} className="form-control" name="name" type="text" onChange={(e) => props.handleChange(e.target)} id="name" placeholder={resource[culture].nameplaceholder} />
                <div style={{ fontSize: 12, color: "red" }}>{props.data.nameError}</div>
            </div>
            <div className="form-group">
                <label htmlFor="comment">{resource[culture].comment}</label>
                <input value={props.data.comment!} className="form-control" name="comment" type="text" onChange={(e) => props.handleChange(e.target)} id="comment" placeholder={resource[culture].commentplaceholder} />
                <div style={{ fontSize: 12, color: "red" }}>{props.data.commentError}</div>
            </div>
            {props.data.logRows.map((row, index) => (

                <div key={index} className="form-group">
                    <label htmlFor="logRow">{resource[culture].logrow} {index + 1}</label>
                    <input value={props.data.logRows[index]!} className="form-control" name="logRow" type="text" onChange={(e) => props.handleText(e.target, index)} id="logRow" placeholder={resource[culture].rowname} />
                    <div style={{ fontSize: 12, color: "red" }}>{props.data.rowErrors[index]}</div>

                    <button style={{ marginTop: "1rem" }} className="btn btn-danger" onClick={e => { props.deleteRow(e, index) }}>{resource[culture].removerow}</button>
                </div>
            ))}
            <button className="btn btn-success" style={{ marginBottom: "1rem" }} onClick={e => { props.addRow(e) }}>{resource[culture].addrow}</button>
            
            <Button variant="outlined" color="primary" className={classes.btn} type="submit">{resource[culture].addbtn}</Button>
            <BackButton classDescription={"btn btn-lg btn-primary btn-block"} btnString={resource[culture].back} />
        </form>
        </Card>
        
        </div>
        </div>     
        </>   
    );
}

export default MaintenanceLogAddForm;