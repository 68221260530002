import React from "react";
import { Route } from "react-router-dom";

const PrivateRouter = ({component, isAuthenticated, redirect, ...rest}: any) => {
    const routeComponent = (props: any) => (
        isAuthenticated
            ? React.createElement(component, props)
            : redirect
    );
    return <Route {...rest} render={routeComponent}/>;
};


export default PrivateRouter;