import { ICulture } from '../domain/ICulture';
import { IWarehouse } from '../domain/warehouse/IWarehouse';
import Axios from 'axios';

export abstract class WarehouseApi {

    private static axios = Axios.create(
        {
            baseURL: process.env.REACT_APP_URLPATH + "Warehouses",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    static async getAll(companyId: number, culture: ICulture, jwt: string): Promise<IWarehouse[]> {
        const url = "";
        try {
            const response = await this.axios.get<IWarehouse[]>(url, { 
                params: { companyId: companyId, culture: culture.code },
                headers: { Authorization: "Bearer " + jwt } 
        });
            //console.log("getAll Response", response);
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.log("error: ", (error as Error).message);
            return [];
        }
    }

    static async getDetails(jwt: string, id: number, culture: ICulture): Promise<IWarehouse | null> {
        const url = "" + id;
        try {
            const response = await this.axios.get<IWarehouse>(url, { 
                params: { culture: culture.code }, 
                headers: { Authorization: "Bearer " + jwt } 
            });
            //console.log('getDetails response', response);
            if (response.status === 200) {
                return response.data;
            }
            return null;
        } catch (error) {
            console.log('error: ', (error as Error).message);
            return null;
        }
    }

}