import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { IUnit } from "../../domain/unit/IUnit";
import UnitForWarehouseTranslations from "../../resources/warehouses/UnitsForWarehouse";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, Typography } from "@material-ui/core";

interface IProps {
    name: string,
    warehouseId: number
    index: number,
    units: IUnit[]
}

const useStyles = makeStyles((theme) => ({
    table: {
        width: "100%",
        height: "100%"
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
      rowStyle: {
          width: "100%",
          padding:"30px 12px",
          height: "2rem",
          border: "1px #ebebeb solid"
      }
  }));

const UnitsForWarehouse = (props: IProps) => {
    const appContext = useContext(AppContext);
    const classes = useStyles();

    const resource = UnitForWarehouseTranslations;
    const culture = appContext.selectedCulture!.code;
    
    return ( true ? 
        <div style={{marginBottom: "6px"}}>
        <Accordion defaultExpanded={false}>
            <AccordionSummary
                expandIcon={"+"}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>{props.name}</Typography>
            </AccordionSummary>
            
            <AccordionDetails>    
            <List className={classes.table}>
            {props.units.map(unit =>
                <ListItem key={unit.id} className={classes.rowStyle} button onClick={() => console.log()} component={Link} to={"/Warehouses/" + unit.warehouseId + "/Units/" + unit.id} >
                {unit.name}
                </ListItem>
                )}
            </List>
            </AccordionDetails>
        </Accordion>
        
        </div>

        :
        <>
            <div className="accordion" id="accordion">
            <div className="card">
            <div className="card-header" id={"heading" + props.index}>
                <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left" style={{ textDecoration: "none" }} type="button" data-toggle="collapse" data-target={"#collapse" + props.index} aria-expanded="true" aria-controls={"collapse" + props.index}>
                        {props.name}
                    </button>
                </h2>
            </div>
            <div id={"collapse" + props.index} className="collapse show" aria-labelledby={"heading" + props.index} data-parent="#accordion">
                <div>
                <table className="table">
                
                <tbody>
                    {props.units.map(unit =>
                        <tr key={unit.id}>
                            <td>{unit.name}</td>
                            <td><Link to={"/Warehouses/" + unit.warehouseId + "/Units/" + unit.id} className="btn btn-primary">{resource[culture].view}</Link></td>
                        </tr>
                    )}
                </tbody>
                </table>
                </div>
            </div>
            </div>
            </div>
        </>
    );
}

export default UnitsForWarehouse;