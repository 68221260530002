import { Checkbox, TableCell } from "@material-ui/core";
import React from "react";
import { IMaintenanceLogRow } from "../../domain/maintenancelogrow/IMaintenanceLogRow";
import { IMaintenanceLogRowEntry } from "../../domain/maintenancelogrowentry/IMaintenanceLogRowEntry";
import { DateFormatter } from "../../utils/Dateutils";

interface IProps {
    maintenanceLogRows: IMaintenanceLogRow[];
    logEntryRows: IMaintenanceLogRowEntry[];
    key: number;
    date: Date;
    userFullName: string;
}

const MaintenanceLogEntryRows = (props: IProps) => {
    return (
        <>
            <TableCell align="right">
                {props.userFullName}
            </TableCell>
            <TableCell align="right">
                {DateFormatter(props.date)}
            </TableCell>
        {props.logEntryRows.map((logRowEntry, index) => 
              <TableCell align="left" key={logRowEntry.sortingNumber}>
                {logRowEntry.done ? <Checkbox disabled checked inputProps={{ 'aria-label': 'disabled checked checkbox' }}/> :
                        <Checkbox disabled inputProps={{ 'aria-label': 'disabled checkbox' }}/>}
              </TableCell>
        )}
        </>
    )
}

export default MaintenanceLogEntryRows;