import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { IProductInUnit } from "../../domain/productinunit/IProductInUnit";
import UnitDetailsTranslations from "../../resources/unit/UnitDetails";

interface IProps {
    products: IProductInUnit[]
}

const GoodsDisplay = (props: IProps) => {

    const appContext = useContext(AppContext);

    const resource = UnitDetailsTranslations;
    const culture = appContext.selectedCulture!.code;

    if (props.products.length === 0) {
        return (
            <div style={{ marginLeft: "1.4rem" }}>
                <ul>
                    <li>{resource[culture].nogoods}</li>
                </ul>
            </div>
        )

    } else {
        return (
            <div style={{ marginLeft: "1.4rem" }}>
                <ul>
                    {props.products.map(productInUnit =>
                        <li key={productInUnit.id}>{productInUnit.productName}</li>
                    )}
                </ul>
            </div>
        )
    }
}

export default GoodsDisplay;