import React, { useEffect, useContext, useState } from "react";
import { MaintenanceLogApi } from "../../services/MaintenanceLogApi";
import { AppContext } from "../../context/AppContext";
import { IMaintenanceLog } from "../../domain/maintenancelog/IMaintenanceLog";
import { Link } from "react-router-dom";
import BackButton from "../shared/BackButton";
import MaintenanceLogIndexTranslations from "../../resources/maintenancelog/MaintenanceLogIndex";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Button, Collapse, makeStyles } from "@material-ui/core";
import { ILatestMaintenanceLogEntry } from "../../domain/maintenancelogentry/ILatestMaintenanceLogEntry";
import { MaintenanceLogEntryApi } from "../../services/MaintenanceLogEntryApi";
import LatesMaintenanceLogEntriesDisplay from "./LatestMaintenanceLogEntriesDisplay";

const useStyles = makeStyles({
    root: {
      minWidth: 275,
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
        marginBottom: "12px"
    },
    pos: {
      marginBottom: 12,
    },
  });

const MaintenanceLogIndex = () => {
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const [maintenanceLogs, setMaintenanceLogs] = useState([] as IMaintenanceLog[]);
    const [latestEntries, setLatestEntries] = useState([] as ILatestMaintenanceLogEntry[])
    const [showLatest, setShowLatest] = useState(false as boolean);
    const [filter, setFilter] = useState('');
    const [isLoading, setIsLoading] = useState(true as boolean);

    const resource = MaintenanceLogIndexTranslations;
    const culture = appContext.selectedCulture!.code;

    useEffect(() => {
        const callApi = async () => {
            const dbMaintenanceLogs = await MaintenanceLogApi.getAll(appContext.jwt!, appContext.companyId!, appContext.selectedCulture!);
            if (dbMaintenanceLogs) {
                setMaintenanceLogs(dbMaintenanceLogs);
            }
            const dbLatestEntries = await MaintenanceLogEntryApi.getLatest(appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
            if (dbLatestEntries) {
                setLatestEntries(dbLatestEntries);
            }
        };

        callApi()
            .then(() => setIsLoading(false));


    }, [appContext.jwt, appContext.companyId, appContext.selectedCulture]);

    if (maintenanceLogs.length === 0) {
        return (
            <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation"}>
                <h1 className="h3 mb-3 font-weight-normal" style={{ textAlign: "center" }}>{resource[culture].nologs}</h1>
                <hr />
                <Link to="/AddLog" className="btn btn-primary">{resource[culture].addnew}</Link>
            </div>
        );
    } else {
        return (
            <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation col-sm-12 col-xs-12 col-md-10 col-lg-10 col-xl-8 mx-auto"}>
                <Card className={classes.root}>
                <Typography className={classes.title} variant="h4" component="h4">
                    {resource[culture].title}
                </Typography>
                <Button variant="outlined" color="primary" onClick={() => setShowLatest(prevShow => !prevShow)}>{resource[culture].latest}</Button>
                </Card>
                <Collapse in={showLatest} className="col align-self-center" style={{padding: "0px"}}>
                    <LatesMaintenanceLogEntriesDisplay entries={latestEntries} />
                    <br/>
                </Collapse>
                <Card>
                <div style={{margin: "1rem"}}>
                <label htmlFor="Input_Email">{resource[culture].search}</label>
                <input id="filter"
                    name="filter"
                    type="text"
                    className="form-control"
                    placeholder="..."
                    value={filter}
                    onChange={event => setFilter(event.target.value)}
                />
                <br/>
                { maintenanceLogs
                    .filter(l => l.name.includes(filter) || filter === '')
                    .map(log =>
                        <Link key={log.id} to={"/MaintenanceLogs/" + log.id} className="list-group-item list-group-item-action">
                            {log.name}
                        </Link>
                )}
                { appContext.roleName === "admin" ? <Link to="/AddLog" className="list-group-item list-group-item-action list-color"><b>+</b> {resource[culture].addnew}</Link> : <></>}
                <hr /> 
                <BackButton classDescription="btn btn-primary btn-margin" btnString={resource[culture].back}/>
                </div>
                </Card>
            </div>
        );
    }
}

export default MaintenanceLogIndex;