export interface ILayoutResourceStrings {
    name: string,
    add: string,
    addUsers: string,
    addComment: string,
    userName: string,
    units: string,
    cleaningtype: string,
    selectUnit: string,
    selectType: string,
    addbtn: string,
    comment: string,
    back: string,
    commentplaceholder: string
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const CleaningFormTranslations: ILayoutResources = {
    [engb]: {
        name: "Name",
        add: "Add a cleaning",
        addUsers: "Add employees",
        addComment: "Add comment",
        userName: "Employee name",
        units: "Unit",
        cleaningtype: "Cleaning type",
        addbtn: "Add",
        selectType: "Select cleaning type",
        selectUnit: "Select unit",
        comment: "Comment",
        back: "Back",
        commentplaceholder: "Add a comment ..."
    },
    [etee]: {
        name: "Nimi",
        add: "Lisa koristus",
        addUsers: "Lisa töötajaid",
        userName: "Töötaja nimi",
        addComment: "Lisa kommentaar",
        units: "Ladu",
        cleaningtype: "Koristustüüp",
        addbtn: "Lisa",
        selectType: "Vali koristustüüp",
        selectUnit: "Vali ladu",
        comment: "Kommentaar",
        back: "Tagasi",
        commentplaceholder: "Lisa kommentaar ..."
    },
}

export default CleaningFormTranslations;