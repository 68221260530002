import { ICulture } from '../domain/ICulture';
import Axios from 'axios';
import { IMaintenanceLogEntry } from '../domain/maintenancelogentry/IMaintenanceLogEntry';
import { IMaintenanceLogEntryCreate } from '../domain/maintenancelogentry/IMaintenanceLogEntryCreate';
import { ILatestMaintenanceLogEntry } from '../domain/maintenancelogentry/ILatestMaintenanceLogEntry';


export abstract class MaintenanceLogEntryApi {

    private static axios = Axios.create(
        {
            baseURL: process.env.REACT_APP_URLPATH + "MaintenanceLogEntries",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    static async getAll(jwt: string, maintenanceLogId: number, culture: ICulture): Promise<IMaintenanceLogEntry[]> {
        const url = "";
        try {
            const response = await this.axios.get<IMaintenanceLogEntry[]>(url, {params: {maintenanceLogId: maintenanceLogId, culture: culture.code}, headers: { Authorization: "Bearer " + jwt }});
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            // console.log("error: ", (error as Error).message);
            return [];
        }
    }

    static async getLatest(companyId: number, jwt: string, culture: ICulture): Promise<ILatestMaintenanceLogEntry[]> {
        const url = "/latest";
        try {
            const response = await this.axios.get<ILatestMaintenanceLogEntry[]>(
                url,
                {
                    params: {
                        companyId: companyId,
                        culture: culture.code
                    },
                    headers: {
                        Authorization: "Bearer " + jwt
                    }
                });
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            // console.log("error: ", (error as Error).message);
            return [];
        }
    }

    static async createNew(jwt: string, maintenanceLogEntry: IMaintenanceLogEntryCreate, culture: ICulture): Promise<IMaintenanceLogEntry | null> {
        const url = "";
        try {
            const response = await this.axios.post(url, maintenanceLogEntry, {params: {culture: culture.code}, headers: { Authorization: "Bearer " + jwt } });
            if (response.status === 201) {
                return response.data;
            }
            return null;
        } catch (error) {
            // console.log('error: ', (error as Error).message);
            return null;
        }
    }
    
    /*
    static async getDetails(jwt: string, id: string): Promise<IMaintenanceLog | null> {
        const url = "" + id;
        try {
            const response = await this.axios.get<IMaintenanceLog>(url, { headers: { Authorization: "Bearer " + jwt } });
            //console.log('getDetails response', response);
            if (response.status === 200) {
                return response.data;
            }
            return null;
        } catch (error) {
            console.log('error: ', (error as Error).message);
            return null;
        }
    }
    */
}