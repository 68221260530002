import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import UnitDetailsTranslations from "../../resources/unit/UnitDetails";
import { ILatestCleaningDisplay } from "../../domain/cleaning/ILatestCleaningDisplay";
import { TableContainer } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { DateFormatter } from "../../utils/Dateutils";

interface IProps {
    cleanings: ILatestCleaningDisplay[]
}

const LatestCleanings = (props: IProps) => {
    const appContext = useContext(AppContext);
    const resource = UnitDetailsTranslations;
    const culture = appContext.selectedCulture!.code;
    
    return (
        <>
        <TableContainer component={Paper}>
        <Table aria-label="simple table">
        <TableHead>
            <TableRow>
                    <TableCell align="right">{resource[culture].date}</TableCell>
                    <TableCell>{resource[culture].cleantype}</TableCell>
                    <TableCell>{resource[culture].unit}</TableCell>
                    <TableCell>{resource[culture].name}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
                {props.cleanings.map((cleaning, index) =>
                <TableRow className="log-row" key={index}>
                    <TableCell align="right">{DateFormatter(new Date(cleaning.recordedAt))}</TableCell>
                    <TableCell>{cleaning.cleaningTypeName}</TableCell>
                    <TableCell>{cleaning.unitName}</TableCell>
                    <TableCell>{cleaning.appUsersFullNames}</TableCell>
                </TableRow>
                )}
        </TableBody>
        </Table>
        </TableContainer>
        </>
    )
}


export default LatestCleanings;