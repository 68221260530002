export interface ILayoutResourceStrings {
    search: string,
    filter: string,
    param: string,
    addFilter: string,
    submitSearch: string,
    searchResultsText: string,
    display: string,
    cleanings: string

}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const CleaningSearchTranslations: ILayoutResources = {
    [engb]: {
        search: "Search",
        filter: "Search filter",
        param: "Parameter",
        addFilter: "Add filter",
        submitSearch: "Search",
        searchResultsText: "Add filters and press search to find cleanings",
        display: "Displaying",
        cleanings: "cleanings"
       
    },
    [etee]: {
        search: "Otsing",
        filter: "Otsingufilter",
        param: "Tekst",
        addFilter: "Lisa filter",
        submitSearch: "Otsi",
        searchResultsText: "Lisa otsingufiltreid ning vajuta otsi nupule",
        display: "Kuvan",
        cleanings: "sissekannet"
    },
}

export default CleaningSearchTranslations;