import React, { useEffect, useContext, useState } from "react";
import { UnitsApi } from "../../services/UnitsApi";
import { AppContext } from "../../context/AppContext";
import GoodsEdit from "./GoodsEdit";
import BackButton from "../shared/BackButton";
import UnitDetailsTranslations from "../../resources/unit/UnitDetails";
import ProductsInUnitsApi from "../../services/ProductsInUnitsApi";
import { IUnitWithIncludes } from "../../domain/unit/IUnitWithIncludes";
import { ProductApi } from "../../services/ProductApi";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core";
import GoodsEditDialog from "./GoodsEditDialog";
import { IProductInUnit } from "../../domain/productinunit/IProductInUnit";
import { useParams } from "react-router-dom";


const useStyles = makeStyles({
    root: {
        marginBottom: "1rem",
        padding: "1rem"
    },
    title: {
        marginBottom: "12px"
    },
    pos: {
      marginBottom: 12,
    },
  });


const UnitEdit = () => {
    const appContext = useContext(AppContext);
    const classes = useStyles();

    const resource = UnitDetailsTranslations;
    const culture = appContext.selectedCulture!.code;
    const [unit, setUnit] = useState({} as IUnitWithIncludes);
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(null as IProductInUnit | null);
    const [isLoading, setIsLoading] = useState(true as boolean);
    let { id } = useParams();

    useEffect(() => {
        const callApi = async () => {
            const data = await UnitsApi.getDetails(appContext.jwt!, id, appContext.selectedCulture!);
            const dbProductsInUnits = await ProductsInUnitsApi.getAll(id, appContext.jwt!);
            const dbProducts = await ProductApi.getAll(appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
            if (dbProducts) {
                for (const productInUnit of dbProductsInUnits) {
                    var name = dbProducts.find(x => x.id === productInUnit.productId)?.name!;
                    productInUnit.productName = name;
                    productInUnit.from = new Date(productInUnit.from);
                }    
            }
            
            if (data && dbProductsInUnits){
                setUnit({
                    id: data.id,
                    name: data.name,
                    warehouseId: data.warehouseId,
                    companyId: data.companyId,
                    productsInUnit: dbProductsInUnits
                });
            }
        };
        callApi()
            .then(() => setIsLoading(false));
    }, [appContext.jwt, id, appContext.selectedCulture, appContext.companyId]);

    const handleClose = () => {
        setOpen(false);
        setModalData(null);
      };

    const handleOpen = (i: number) => {
        var d = unit.productsInUnit[i]
        setModalData(d);
        setOpen((open) => !open);
    }


    const removeProduct = async (event: React.MouseEvent<HTMLButtonElement>, productInUnitId: number) => {
        event.preventDefault();
        var array = [...unit.productsInUnit];
        var ind = array.findIndex(x => x.id === productInUnitId)
        array.splice(ind, 1);
        setUnit({ ...unit, productsInUnit: array })
        await ProductsInUnitsApi.delete(productInUnitId, appContext.jwt!);
        handleClose();
    }

    return (
        <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation"}>
        <div className="row">
        <div className="col-sm-10 col-xs-12 col-md-6 col-lg-6 col-xl-6 mx-auto">
        <Card className={classes.root}>
            <Typography className={classes.title} variant="h3" component="h2">
                {resource[culture].details}
            </Typography>
            
            <dl className="row">
                <dt className="col-sm-2">{resource[culture].name}</dt>
                <dd className="col-sm-10">{unit.name}</dd>
            </dl>
            <GoodsEdit productsInUnit={unit.productsInUnit} unitId={unit.id} warehouseId={unit.warehouseId} removeProduct={removeProduct} handleOpen={handleOpen}/> <br/>
            <BackButton classDescription="btn btn-primary" btnString={resource[culture].back}/>
        </Card>

        <GoodsEditDialog handleClose={handleClose} open={open} removeProduct={removeProduct} data={modalData}/>
        </div>
        </div>
        </div>
    );
}

export default UnitEdit;