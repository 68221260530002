import React, { useState, useContext, useEffect } from "react";
import DisplayCleanings from "./DisplayCleanings";
import { AppContext } from "../../context/AppContext";
import GoodsDisplay from "./GoodsDisplay";
import { Link, useParams } from "react-router-dom";
import UnitDetailsTranslations from "../../resources/unit/UnitDetails";
import { IUnitWithIncludes } from "../../domain/unit/IUnitWithIncludes";
import { IProductInUnit } from "../../domain/productinunit/IProductInUnit";
import { UnitsApi } from "../../services/UnitsApi";
import { ProductApi } from "../../services/ProductApi";
import ProductsInUnitsApi from "../../services/ProductsInUnitsApi";
import { CleaningApi } from "../../services/CleaningApi";
import { ICleaningDisplay } from '../../domain/cleaning/ICleaningDisplay';
import { CleaningTypeApi } from "../../services/CleaningTypeApi";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core";
import { CleaningGoalApi } from "../../services/CleaningGoalApi";
import { ICleaningGoal } from "../../domain/cleaninggoal/ICleaningGoal";
import CleaningGoalsDisplay from "./CleaningGoalsDisplay";

const initialState = {
    id: 0,
    name: "",
    warehouseId: 0,
    companyId: 0,
    productsInUnit: [] as IProductInUnit[]

}


const useStyles = makeStyles({
    root: {
      minWidth: 275,
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
        marginBottom: "12px"
    },
    pos: {
      marginBottom: 12,
    },
  });

const UnitIndex = () => {
    
    const classes = useStyles();
    let { id } : any = useParams();
    const appContext = useContext(AppContext);
    const [state, setState] = useState(initialState as IUnitWithIncludes);
    const [cleanings, setCleanings] = useState([] as ICleaningDisplay[]);
    const [cleaningGoals, setCleaningGoals] = useState([] as ICleaningGoal[]);
    const [isLoading, setIsLoading] = useState(true as boolean);
    const resource = UnitDetailsTranslations;
    const culture = appContext.selectedCulture!.code;
    
    useEffect(() => {
        setIsLoading(true);
        const callApis = async () => {
            const unit = await UnitsApi.getDetails(appContext.jwt!, id, appContext.selectedCulture!);
            const dbProducts = await ProductApi.getAll(appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
            const dbProductsInUnits = await ProductsInUnitsApi.getAll(id, appContext.jwt!);
            if (dbProducts) {
                for (const productInUnit of dbProductsInUnits) {
                    var name = dbProducts.find(x => x.id === productInUnit.productId)?.name!;
                    productInUnit.productName = name;
                }    
            }
            if (unit) {
                setState(
                    {
                        name: unit.name,
                        id: unit.id, 
                        warehouseId: unit.warehouseId, 
                        companyId: unit.companyId, 
                        productsInUnit: dbProductsInUnits
                    }
                );
            }

            const dbCleanings = await CleaningApi.getAll(id, appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
            const dbCleaningTypes = await CleaningTypeApi.getAll(appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
            const dbCleaningGoals = await CleaningGoalApi.getAll(id, appContext.jwt!, appContext.selectedCulture!);

            if (dbCleaningGoals) {
                dbCleaningGoals.forEach(cg => {
                    cg.cleaningTypeName = dbCleaningTypes.find(type => type.id === cg.cleaningTypeId)?.name!
                });
                setCleaningGoals(dbCleaningGoals);
            }

            let cleanings = [] as ICleaningDisplay[];
            dbCleanings.forEach(element => {
                cleanings.push(
                    {
                        id: element.id,
                        appUserFullNames: element.appUsersFullNames,
                        comment: element.comment,
                        recordedAt: new Date(element.recordedAt),
                        unitId: element.unitId,
                        cleaningTypeId: element.cleaningTypeId,
                        cleaningTypeName: dbCleaningTypes.find(type => type.id === element.cleaningTypeId)?.name!
                    } as ICleaningDisplay
                )
            });
            setCleanings(cleanings.sort((a, b) => b.recordedAt.getTime() - a.recordedAt.getTime()));

        };
        callApis()
            .then(() => setIsLoading(false));

    }, [appContext.companyId, appContext.jwt, id, appContext.selectedCulture])

  

    return (
        <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation"}>
            <Card className={classes.root}>
            <Typography className={classes.title} variant="h3" component="h2">
                {state.name}
            </Typography>
            <GoodsDisplay products={state.productsInUnit}/>
            <hr/>
            <CleaningGoalsDisplay goals={cleaningGoals}/>
            <div style={{marginBottom:"2rem"}}>
                <Link className="btn btn-primary" to="/Warehouses">{resource[culture].backtolist}</Link>
                {"   "}
                {
                    appContext.roleName === "admin" ? <Link className="btn btn-secondary" to={"/Warehouses/" + state.warehouseId + "/Units/" + state.id + "/Details"}>{resource[culture].details}</Link> : <></>
                }
                
            </div>
            </Card>

            <DisplayCleanings cleanings={cleanings} />
        </div>
    );
}

export default UnitIndex;