import { ErrorDTO } from './../domain/ErrorDTO';
import { IProductInUnitCreate } from './../domain/productinunit/IProductInUnitCreate';
import Axios from 'axios';
import { IProductInUnit } from '../domain/productinunit/IProductInUnit';

export abstract class ProductsInUnitsApi {

    private static axios = Axios.create(
        {
            baseURL:  process.env.REACT_APP_URLPATH + "ProductsInUnits/",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    static async getAll(unitId: number, jwt: string): Promise<IProductInUnit[]> {
        const url = "";
        try {
            const response = await this.axios.get<IProductInUnit[]>(url, { params: {unitId: unitId}, headers: { Authorization: "Bearer " + jwt }});
            //console.log("getAll Response", response);
            if (response.status === 200) {
                return response.data;
            }
            if(response.status >= 400) {
                console.log(response.data);
            }
            return [];
        } catch (error) {
            console.log("error: ", (error as ErrorDTO).message);
            return [];
        }
    }

    static async createNew(jwt: string, productInUnit: IProductInUnitCreate): Promise<void> {
        const url = "";
        try {
            const response = await this.axios.post(url, productInUnit, { headers: { Authorization: "Bearer " + jwt } });
            console.log('Create response', response);
            if (response.status === 200) {
                return response.data;
            }
            if(response.status === 400) {
                console.log(response.data);
            }
            return;
        } catch (error) {
            console.log('error: ', (error as ErrorDTO).message);
        }
    }

    static async delete(id: number, jwt: string): Promise<void> {
        const url = "" + id;
        try {
            const response = await this.axios.delete<IProductInUnit>(url, { headers: { Authorization: "Bearer " + jwt } });
            console.log('delete response', response);
            if (response.status === 200) {
                return;
            }
            if(response.status >= 400) {
                console.log(response.data);
            }
            return;
        } catch (error) {
            console.log('error: ', (error as ErrorDTO).message);
        }
    }


}

export default ProductsInUnitsApi;