export interface ILayoutResourceStrings {
    name: string,
    title: string,
    comment: string,
    back: string,
    nologs: string,
    addnew: string,
    search: string,
    latest: string,
    date: string,
    user: string,
    log: string
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const MaintenanceLogIndexTranslations: ILayoutResources = {
    [engb]: {
        name: "Name",
        title: "Maintenance logs",
        nologs: "No logs to display!",
        addnew: "Add new maintenance log",
        comment: "Comment",
        back: "Back",
        search: "Search",
        latest: "Latest entries",
        date: "Date",
        user: "Name",
        log: "Log"
    },
    [etee]: {
        name: "Nimetus",
        title: "Logiraamatud",
        nologs: "Logiraamatuid pole!",
        addnew: "Lisa uus logiraamat",
        comment: "Kommentaar",
        back: "Tagasi",
        search: "Otsing",
        latest: "Viimased sissekanded",
        date: "Kuupäev",
        user: "Nimi",
        log: "Logiraamat"
    },
}

export default MaintenanceLogIndexTranslations;