import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { CleaningApi } from "../../services/CleaningApi";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Box, Button, makeStyles } from "@material-ui/core";
import CleaningSearchTranslations from "../../resources/cleaning/CleaningSearch";
import { ILatestCleaningDisplay } from "../../domain/cleaning/ILatestCleaningDisplay";
import LatestCleanings from "../cleaning/LatestCleanings";

const useStyles = makeStyles({
    root: {
      minWidth: 275,
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
        marginBottom: "12px"
    },
    pos: {
      marginBottom: 12,
    },
    buttons: {
        marginRight: 3,
        marginBottom: 3
    }
  });

  export interface ISearchState {
    filter: string;
    value: string;
    
}

const initialState = {
    filter: "",
    value: "",
}


const CleaningSearch = () => {
    const classes = useStyles();
    const appContext = useContext(AppContext);
    const [cleanings, setCleanings] = useState([] as ILatestCleaningDisplay[]);
    const [params, setParams] = useState([] as string[]);
    const [isLoading, setIsLoading] = useState(true as boolean);
    const [searchParams, setSearchParams] = useState([] as ISearchState[]);
    const [searchBoxes, setSearchBoxes] = useState(initialState as ISearchState)
    const resource = CleaningSearchTranslations;
    const culture = appContext.selectedCulture!.code;

    useEffect(() => {
        setIsLoading(true);
        const callApi = async () => {
            const data = await CleaningApi.getSearchParams(appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
            if (data) {
                setParams(data);
            }
        };
        callApi()
        .then(() => setIsLoading(false));
        
    }, [appContext.companyId, appContext.jwt, appContext.selectedCulture]);

    const handleChange = (target:
        EventTarget & HTMLInputElement |
        EventTarget & HTMLSelectElement |
        EventTarget & HTMLTextAreaElement
    ) => {
        if (target.type === "email") {
            setSearchBoxes({ ...searchBoxes, [target.name]: target.value });
        }

        if (target.type === "select-one" && target instanceof HTMLSelectElement) {
            setSearchBoxes({ ...searchBoxes, [target.name]: target.value });
        }
    }

    const submitSearch = async (params: ISearchState[]) => {
        var jsonData: any = {};
        params.forEach((p) => {
            jsonData[p.filter] = p.value
        })
        var data = await CleaningApi.searchCleanings(jsonData, appContext.companyId!, appContext.jwt!, appContext.selectedCulture!);
        setCleanings(data);    
    }

    const addFilter = () => {
        if (searchBoxes.filter !== "-") {
            if (searchBoxes.value && searchBoxes.value.trim()) {
                var filter = {
                    filter: searchBoxes.filter,
                    value: searchBoxes.value
                }
                var searchParam = searchParams.find(s => s.filter === searchBoxes.filter);
                if (searchParam !== undefined) {
                    var sp = searchParam!;
                    var index = searchParams.indexOf(sp);
                    var array = [...searchParams];
                    array.splice(index, 1);
                    array.push(filter);
                    setSearchParams(array)
                    setSearchBoxes(initialState);
                } else {
                    setSearchParams([...searchParams, filter]);
                    setSearchBoxes(initialState);
                }
            }
        }
    }

    const removeFilter = (index: number) => {
        var array = [...searchParams];
        array.splice(index, 1);
        setSearchParams(array);
    }

    return (
        <div className={isLoading ? "container-fluid hidden" : "container-fluid div-animation"}>
            <Card className={classes.root}>
                <Typography className={classes.title} variant="h3" component="h2">
                    {resource[culture].search}
                </Typography>

                <div style={{ width: '100%' }}>
                    <Box display="flex" flexWrap="wrap" p={1} bgcolor="background.paper">
                        <Box p={1} order={1} >
                        <label htmlFor="exampleFormControlSelect1">{resource[culture].filter}</label>
                        <select value={searchBoxes.filter} name="filter" onChange={(e) => handleChange(e.target)} className="form-control" id="exampleFormControlSelect1">
                            <option aria-label="-" value="-">--choose filter--</option>
                            { params.map((param, i) => { return (<option key={i} value={param}>{param}</option>); }) }
                        </select>
                        </Box>

                        <Box p={1} order={2}>
                            <label htmlFor="Input_Email">{resource[culture].param}</label>
                            <input value={searchBoxes.value} className="form-control" name="value" onChange={(e) => handleChange(e.target)} type="email" placeholder="" />
                        </Box>
                    </Box>
                    {searchParams.length > 0 ?
                        <Box display="flex" p={1} bgcolor="background.paper">
                            <Box p={1} order={3}>
                                { searchParams.map((search, i) => {
                                    return (
                                    <Button key={i} className={classes.buttons} variant="outlined" color="secondary" onClick={() => removeFilter(i)}>{search.filter}: {search.value}</Button>
                                    )
                                })}
                            </Box>
                        </Box>
                    :
                        <></>
                    }
                    <Box display="flex" p={1} bgcolor="background.paper">
                        <Box p={1} order={3}>
                            <Button className={classes.buttons} variant="outlined" color="primary" onClick={() => addFilter()}>{resource[culture].addFilter}</Button>
                            <Button className={classes.buttons} variant="outlined" color="primary" onClick={() => submitSearch(searchParams)}>{resource[culture].submitSearch}</Button>
                        </Box>
                    </Box>
                 </div>
                <hr/>
                <div>
                {
                    cleanings.length > 0 ? 
                    <>
                    <div className={classes.pos}><b>{resource[culture].display} {cleanings.length} {resource[culture].cleanings}</b></div>
                    <LatestCleanings cleanings={cleanings}/>
                    </>
                    :
                    <>
                        <hr/>
                        <h4>{resource[culture].searchResultsText}</h4>
                    </>
                }
                </div>
            </Card>
        </div>
    );
}

export default CleaningSearch;