import Axios from 'axios';
import { IUserInCleaningCreate } from '../domain/userincleaning/IUserInCleaningCreate';

export abstract class UserInCleaningApi {

    private static axios = Axios.create(
        {
            baseURL: process.env.REACT_APP_URLPATH + "UsersInCleanings",
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    static async createNew(jwt: string, userInCleaning: IUserInCleaningCreate): Promise<void> {
        const url = "";
        try {
            const response = await this.axios.post(
                url, 
                userInCleaning, 
                { 
                    headers: { 
                        Authorization: "Bearer " + jwt 
                    } 
                });

            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
}