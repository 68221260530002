import React, { useState, useContext, FormEvent } from "react";
import { AppContext } from "../../context/AppContext";
import MaintenanceLogAddForm from "./MaintenanceLogAddForm";
import { MaintenanceLogApi } from "../../services/MaintenanceLogApi";
import { MaintenanceLogRowApi } from "../../services/MaintenanceLogRowApi";
import { useHistory } from "react-router-dom";

export interface IMaintenanceLogState {
    name: string;
    comment?: string;
    companyId: string;
    logRows: string[];
    rowErrors: string[];
    nameError: string;
    commentError: string;
    companyError: string;
}

const initialState = {
    name: "",
    comment: "",
    companyId: "",
    logRows: [],
    rowErrors: [],
    nameError: "",
    commentError: "",
    companyError: ""
}

const MaintenanceLogAdd = () => {
    const appContext = useContext(AppContext);
    const history = useHistory();

    const [state, setState] = useState(initialState as IMaintenanceLogState);

    const handleChange = (target:
        EventTarget & HTMLInputElement |
        EventTarget & HTMLSelectElement |
        EventTarget & HTMLTextAreaElement
    ) => {
        if (target.type === "text" && target.name === "comment") {
            setState({ ...state, [target.name]: target.value });
        }

        if (target.type === "text" && target.name === "name") {
            setState({ ...state, [target.name]: target.value });
        }
    }

    const validation = () => {
        let ne = "";
        let ce = "";
        let valid = true;
        state.rowErrors.forEach(rowError => {
            if (rowError !== "") {
                valid = false;
            }
        });

        if (state.name) {
            if (state.name.length > 128) {
                valid = false;
                ne = "Name is too long!";
            } else if (state.name.length < 5 || state.name === "") {
                ne = "Name is too short!";
            }
        } else {
            ne = "Name is required!";
        }

        if (state.comment) {
            if (state.comment.length > 128) {
                valid = false;
                ce = "Comment is too long!";
            } else {
                ce = "";
            }
        }
        setState({ ...state, nameError: ne, commentError: ce });
        return valid;
    }
    const createMaintenanceLog = async () => {
        return await MaintenanceLogApi.createNew(appContext.jwt!, { name: state.name, comment: state.comment, companyId: appContext.companyId! }, appContext.selectedCulture!);
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isValid = validation();
        console.log(state);
        if (isValid) {
            console.log(state);
            createMaintenanceLog()
                .then(log => {
                    if (log) {
                        state.logRows.forEach(async (row, increment = 0) => {
                            await MaintenanceLogRowApi.createNew(appContext.jwt!, log.id, { description: row, maintenanceLogId: log.id, sortingNumber: increment }, appContext.selectedCulture!)
                            increment = increment + 1;
                        });
                        history.push("/MaintenanceLogs");
                    }
                });
            // TODO: add the MaintenanceLog, get the maintenanceLogId, add the maintenanceLogRows connected to that Id
        }
    }

    const handleText = (target: EventTarget & HTMLInputElement, index: number) => {
        let rows = [...state.logRows]
        let errors = [...state.rowErrors];
        rows[index] = target.value
        if (target.value.length > 128 || (target.value.length < 3 && target.value.length !== 0)) {
            errors[index] = "Row description length has to be between 5 and 128";
        } else {
            errors[index] = "";
        }
        setState({ ...state, logRows: rows, rowErrors: errors })
    }

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number) => {
        e.preventDefault()
        let rows = [
            ...state.logRows.slice(0, i),
            ...state.logRows.slice(i + 1)
        ]
        setState({ ...state, logRows: rows })
    }

    const addRow = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        let rows = state.logRows.concat([''])
        setState({ ...state, logRows: rows });
    }

    return (
        <div className="container-fluid">

            <MaintenanceLogAddForm
                data={state}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                deleteRow={handleDelete}
                addRow={addRow}
                handleText={handleText} />
        </div>
    )
}

export default MaintenanceLogAdd;