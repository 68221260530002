export interface ILayoutResourceStrings {
    logIn: string,
    email: string,
    password: string,
    company: string,
    select: string,
    wrongLogin: string
}

export interface ILayoutResources {
    [key: string]: ILayoutResourceStrings,
}

const engb = "en-GB";
const etee = "et-EE";

export const LoginTranslations: ILayoutResources = {
    [engb]: {
        logIn: "Log in",
        email: "Email address",
        password: "Password",
        company: "Company",
        select: "Select company",
        wrongLogin: "Wrong email or password!"
    },
    [etee]: {
        logIn: "Logi sisse",
        email: "Email",
        password: "Parool",
        company: "Ettevõte",
        select: "Vali ettevõte",
        wrongLogin: "Vale e-maili aadress või parool!"
    },
}

export default LoginTranslations;