import { ICulture } from '../domain/ICulture';
import Axios from 'axios';
import { ICompany } from '../domain/company/ICompany';

export abstract class CompanyApi {

    private static axios = Axios.create(
        {
            baseURL: process.env.REACT_APP_URLPATH + "Companies",
            headers: {
                 'Content-Type': 'application/json'
            }
        }
    );

    static async getAll(culture: ICulture): Promise<ICompany[]> {
        const url = "";
        try {
            const response = await this.axios.get<ICompany[]>(url, { params: { culture: culture.code } });
            //console.log("getAll Response", response);
            if (response.status === 200) {
                return response.data;
            }
            return [];
        } catch (error) {
            console.log("error: ", (error as Error).message);
            return [];
        }
    }
}