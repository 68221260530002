import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Header from "./components/shared/Header";
import Home from "./components/home/Home";
import About from "./components/shared/About";
import Form from "./components/shared/Form";
import "./style.css";
import { AppContextProvider, AppContextInitialState, IAppContext } from "./context/AppContext";
import Login from "./components/user/Login";
import WarehouseIndex from "./components/warehouses/WarehousesIndex";
import UnitIndex from "./components/units/UnitIndex";
import AddCleaning from "./components/cleaning/AddCleaning";
import UnitEdit from "./components/units/UnitEdit";
import AddGoods from "./components/units/AddGoods";
import MaintenanceLogIndex from "./components/maintenancelogs/MaintenanceLogIndex";
import MaintenanceLogAdd from "./components/maintenancelogs/MaintenanceLogAdd";
import MaintenanceLogDetails from "./components/maintenancelogs/MaintenanceLogDetails";
import MaintenanceLogEntryAdd from "./components/maintenancelogs/MaintenanceLogEntryAdd";
import { ICulture } from "./domain/ICulture";
import "dotenv"
import PrivateRouter from "./components/shared/PrivateRouter";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import Profile from "./components/user/Profile";
import Footer from "./components/shared/Footer";
import CleaningSearch from "./components/cleaningsearch/CleaningSearch";
import { loadState, saveState } from "./LocalStorage"

const theme = createMuiTheme({
    typography: {
     fontSize: 15,
     "fontFamily": `"Noto Sans JP"`,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500,
    }
 });

const App = () => {
    const setJwt = (jwt: string) => { setAppState({ ...appState, jwt: jwt }); };

    const setUserName = (userName: string) => { setAppState({ ...appState, userName }); };

    const setAll = (jwt: string, userName: string, companyId: number, roleName: string, firstName: string, lastName: string) => {
        setAppState(prevState => ({ ...prevState, jwt: jwt, userName: userName, companyId: companyId, roleName: roleName, firstName: firstName, lastName: lastName }));
        saveState({...appState, jwt: jwt, userName: userName, companyId: companyId, roleName: roleName, firstName: firstName, lastName: lastName, selectedCulture: appState.selectedCulture});
    };

    const setCulture = (culture: ICulture) => {
        var s = loadState();
        console.log("set culture");
        if (s !== null) {    
            saveState({...s, selectedCulture: culture});
            console.log("saved state: ", {...s, selectedCulture: culture});
            setAppState(prev => (
                { ...prev, 
                    jwt: s!.jwt, 
                    userName: s!.userName, 
                    companyId: s!.companyId, 
                    roleName: s!.roleName, 
                    firstName: s!.firstName, 
                    lastName: s!.lastName, 
                    selectedCulture: culture 
                }));
        } else {
            saveState({...initialAppState, selectedCulture: culture});
            setAppState(prev => ({ ...prev, selectedCulture: culture }));
        }
    };    

    const initialAppState = {
        ...AppContextInitialState,
        setJwt,
        setUserName,
        setAll,
        setCulture,
    } as IAppContext;

    const [appState, setAppState] = useState(initialAppState);
    
    useEffect(() => {
        if (localStorage.getItem("state") !== null) {
            var s = loadState();
            if (s !== null) {
                setAppState(s);
                setAppState({ ...s, setJwt: setJwt, setAll: setAll, setCulture: setCulture, setUserName: setUserName});
            }
        }
    // eslint-disable-next-line
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
        <AppContextProvider value={appState}>
        <Router>
        <Header />

            <main role="main" className="pb-3">
            <Switch>
                {/* Various misc. */}
                <Route path="/About/"> <About /> </Route>                        

                <PrivateRouter exact path="/Warehouses" 
                    isAuthenticated={appState.jwt !== null} 
                    component={WarehouseIndex} 
                    redirect={<Redirect to={{pathname: '/Login'}}/>}>
                </PrivateRouter>
                
                {/* Unit management, adding cleanings */}
                <PrivateRouter exact path="/Warehouses/:warehouseId/Units/:id" 
                    isAuthenticated={appState.jwt !== null} 
                    component={UnitIndex} 
                    redirect={<Redirect to={{pathname: '/Login'}}/>}>
                </PrivateRouter>

                <PrivateRouter exact path="/Warehouses/:warehouseId/Units/:id/Details"  
                    isAuthenticated={appState.roleName === "admin"} 
                    component={UnitEdit} 
                    redirect={<Redirect to={{pathname: '/'}}/>}>
                </PrivateRouter>

                <PrivateRouter exact path="/Warehouses/:warehouseId/Units/:id/AddGoods" 
                    isAuthenticated={appState.roleName === "admin"} 
                    component={AddGoods} 
                    redirect={<Redirect to={{pathname: '/'}}/>}>
                </PrivateRouter>

                <PrivateRouter path="/Cleaning" 
                    isAuthenticated={appState.roleName === "admin" || appState.roleName === "employee"} 
                    component={AddCleaning} 
                    redirect={<Redirect to={{pathname: '/'}}/>}>
                </PrivateRouter>
                <PrivateRouter path="/CleaningSearch" 
                    isAuthenticated={appState.roleName === "admin" || appState.roleName === "employee"} 
                    component={CleaningSearch} 
                    redirect={<Redirect to={{pathname: '/'}}/>}>
                </PrivateRouter>
                
                {/* MaintenanceLogs */}
                <PrivateRouter exact path="/MaintenanceLogs" 
                    isAuthenticated={appState.roleName === "admin" || appState.roleName === "employee"} 
                    component={MaintenanceLogIndex} 
                    redirect={<Redirect to={{pathname: '/'}}/>}>
                </PrivateRouter>
                <PrivateRouter exact path="/MaintenanceLogs/:id" 
                    isAuthenticated={appState.roleName === "admin" || appState.roleName === "employee"} 
                    component={MaintenanceLogDetails} 
                    redirect={<Redirect to={{pathname: '/'}}/>}>
                </PrivateRouter>
                <PrivateRouter path="/AddLog" 
                    isAuthenticated={appState.roleName === "admin"} 
                    component={MaintenanceLogAdd} 
                    redirect={<Redirect to={{pathname: '/'}}/>}>
                </PrivateRouter>
                <PrivateRouter exact path="/MaintenanceLogs/:id/AddLogEntry" 
                    isAuthenticated={appState.roleName === "admin" || appState.roleName === "employee"} 
                    component={MaintenanceLogEntryAdd} 
                    redirect={<Redirect to={{pathname: '/'}}/>}>
                </PrivateRouter>

                {/* Discontinued forms */}
                <Route path="/Form"> <Form /> </Route>
                {/*  This endpoint is disabled for now. Only registration is through admin.
                    <Route exact path="/Register"> <Register /> </Route>
                */}

                {/* User profile and Home */}
                <Route exact path="/Login"> <Login /> </Route>
                <PrivateRouter path="/Profile" 
                    isAuthenticated={appState.jwt !== null} 
                    component={Profile} 
                    redirect={<Redirect to={{pathname: '/Login'}}/>}>
                </PrivateRouter>
                <Route exact path="/"> <Home /> </Route>

                {/* Page not found */}
                <div className="container-fluid"><h1>404 Page not found</h1></div>
            </Switch>
        </main>
        <Footer />
        </Router>
        </AppContextProvider>
        </MuiThemeProvider>
    );
}
/*
<Route path="/About/"> <About name={appState.userName} enabled={true} id={appState.jwt!} /> </Route>
<Route path="/Privacy"> <Privacy count1={5} count2={10} /> </Route>

<Route path="/Companies"> <Companies /> </Route>

<Route path="/Warehouses"> <WarehouseIndex /> </Route>

<Route path="/Units"> <UnitIndex companyId={appState.companyId!} warehouseId={appState.warehouseId!} /> </Route>
<Route exact path="/Unit/Details"> <UnitEdit id={appState.unitId!} /></Route>
<Route exact path="/AddGoods"> <AddGoods unitId={appState.unitId!} /></Route>
<Route path="/Cleaning"> <AddCleaning /> </Route>

<Route path="/MaintenanceLogs"> <MaintenanceLogIndex/> </Route>
<Route path="/MaintenanceLog"> <MaintenanceLogDetails maintenanceLogId={appState.maintenanceLogId!} /> </Route>
<Route path="/AddLog"> <MaintenanceLogAdd /> </Route>
<Route path="/AddLogEntry"> <MaintenanceLogEntryAdd maintenanceLogId={appState.maintenanceLogId!} /> </Route>

<Route path="/Form"> <Form /> </Route>
<Route exact path="/Register"> <Register /> </Route>

<Route exact path="/Login"> <Login /> </Route>
<Route exact path="/Profile"> <Profile /> </Route>
<Route exact path="/"> <Home /> </Route>
*/
export default App;

// Raadiator puhastatud rida logidesse
// Kokkuvõttev e-mail tegemata logitöödest