import React, { FormEvent, useContext } from "react";
import { ILoginState } from "./Login";
import { ICompany } from "../../domain/company/ICompany";
import { AppContext } from "../../context/AppContext";
import LoginTranslations from "../../resources/user/Login";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core";

interface IProps {
    data: ILoginState;
    handleChange: (target:
        EventTarget & HTMLInputElement |
        EventTarget & HTMLSelectElement
    ) => void;
    companies: ICompany[]
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

const useStyles = makeStyles({
    root: {
      marginBottom: "1rem",
      padding: "1rem"
    },
    title: {
      marginBottom: "12px",
      textAlign: "center"

    },
    pos: {
      marginBottom: 12,
    },
  });

const LoginForm = (props: IProps) => {
    const appContext = useContext(AppContext);
    const classes = useStyles();

    const resource = LoginTranslations;
    const culture = appContext.selectedCulture!.code;

    return (
        <>
        
        <div className="row">
        <div className="col-sm-10 col-xs-12 col-md-6 col-lg-6 col-xl-4 mx-auto">            
        <Card className={classes.root}>
        <Typography className={classes.title} variant="h4" component="h4">
            {resource[culture].logIn}
        </Typography>
        
        <form className="form-signin" onSubmit={props.handleSubmit}>
                
                <label htmlFor="Input_Email">{resource[culture].email}</label>
                <input value={props.data.email} className="form-control" name="email" onChange={(e) => props.handleChange(e.target)} type="email" placeholder="" />
                <div style={{fontSize: 12, color: "red"}}>{props.data.emailError}</div>
                <label htmlFor="inputPassword" >{resource[culture].password}</label>
                <input value={props.data.password} className="form-control" name="password" onChange={(e) => props.handleChange(e.target)} type="password" placeholder="" />
                <div style={{fontSize: 12, color: "red"}}>{props.data.passwordError}</div>
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">{resource[culture].company}</label>
                    <select value={props.data.domain} name="domain" onChange={(e) => props.handleChange(e.target)} className="form-control" id="exampleFormControlSelect1">
                        <option value="" key={1}>-- {resource[culture].select} --</option>
                        {
                            props.companies.map((item, index) => (
                                <option value={item.id} key={index}>{item.name}</option>
                            ))
                        }

                    </select>
                    <div style={{fontSize: 12, color: "red"}}>{props.data.domainError}</div>

                </div>

                <button className="btn btn-lg btn-primary btn-block" type="submit">{resource[culture].logIn}</button>
                <br></br>
                {/**
                <div className="form-group">
                    <p>
                        <NavLink to="/Register">Register as a new user</NavLink>
                    </p>
                </div>
                 */}
            </form>
            </Card>
        </div>
        </div>
        </>
    );
}

export default LoginForm;